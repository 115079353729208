import React, { useEffect, useState } from "react";
import closeSuccessIcon from "../../assets/images/close_success.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { missingPopup } from "../../helpers/dataHelper/missingData";
// import { api } from "../../helpers/apiHelper/requestHelper";
import { toastify } from "../../helpers/toast/toastify";
import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import useApi from "../../helpers/apiHelper/requestHelper";
import Button from "../../components/button/Button";
import { Form } from "react-bootstrap";
import validator from "validator";
import InputComponent from "../../components/input/InputComponent";

export default function CreatePassword() {
  const api = useApi();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { state } = useLocation();
  const user = useSelector((state) => state?.user?.user);
  const [validationMessage, setValidationMessage] = useState("");
  const [focusedInput, setFocusedInput] = useState(null);
  const { id } = useParams();

  const handlePasswordChange = (value) => {
    const newPassword = value;
    if (!newPassword) {
      setValidationMessage("");
    } else if (!/[A-Z]/.test(newPassword)) {
      setValidationMessage(
        "Password must contain at least one uppercase letter"
      );
    } else if (!validator.isLength(newPassword, { min: 8 })) {
      setValidationMessage("Password must be at least 8 characters long");
    } else if (!/[0-9]/.test(newPassword)) {
      setValidationMessage("Password must contain at least one digit");
    } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword)) {
      setValidationMessage(
        "Password must contain at least one special character"
      );
    } else {
      setValidationMessage(""); // Clear validation message if all rules are satisfied
    }

    setPassword(newPassword);
  };

  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };

  useEffect(() => {
    if (user) {
      if (user?.role === "leader")
        navigate("/leader/dashboard", { replace: true });
      else if (user?.role === "member")
        navigate("/member/dashboard", { replace: true });
      else if (user?.role === "admin") navigate("/admin/dashboard");
    }
  }, [window.location, user]);

  const resetPassword = async () => {
    if (validationMessage !== "") {
      toastify("Please enter a valid password");
      return;
    }
    if (password != confirmPassword) {
      toastify("Passwords do not match");
    }
    if (!password) {
      missingPopup("Password");
    } else if (!confirmPassword) {
      missingPopup("Confirm Password");
    } else if (password == confirmPassword) {
      const response = await api("post", "auth/resetpassword", {
        otp: id,
        password,
      });
      if (response?.status) {
        toastify("Password changed successfully.", "success");
        navigate("/login");
      } else {
        toastify(response?.message);
      }
    }
  };

  return (
    <div className="common_style">
      <Header />

      <section className="sign_section d-flex align-items-center">
        <div className="login_card account_card">
          <div className="container">
            <div className="row justify-content-center">
              <div className="">
                <h2 className="fs20 text_GB m-9">Create new password</h2>
                <div className="mb-26 height-14px"></div>
              </div>
            </div>
            <div className="row justify-content-centerpb-2 pt-1">
              <div className="d-grid px-0">
                <div className="pb-13">
                  <InputComponent
                    type="password"
                    label="Password"
                    focusedInput={focusedInput}
                    handleFocus={handleFocus}
                    handleBlur={handleBlur}
                    value={password}
                    onChange={(val) => handlePasswordChange(val)}
                  />
                  {validationMessage && (
                    <div className="text-danger">{validationMessage}</div>
                  )}
                </div>
                <div>
                  <InputComponent
                    type="password"
                    label="Verify Password"
                    focusedInput={focusedInput}
                    handleFocus={handleFocus}
                    handleBlur={handleBlur}
                    value={confirmPassword}
                    onChange={setConfirmPassword}
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="d-flex justify-content-center w-100 px-0">
                <div className="mt-19 w-100">
                  <button
                    className="cyan-btn newCreateButton w-100"
                    onClick={resetPassword}
                  >
                    Save Password
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="RESET"
        tabIndex="-1"
        aria-labelledby="RESETLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body position-relative">
              <img
                src={closeSuccessIcon}
                alt="img"
                data-bs-dismiss="modal"
                aria-label="Close"
                className="position-absolute"
                role="button"
              />

              <p className="fs16 text-700 text-center">
                PASSWORD <br />
                RESET
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

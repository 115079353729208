import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import copyIcon from "../../../assets/images/Copy.svg";
import Discount from "../../../assets/images/4.gif";
import X from "../../../assets/images/x.jpg";
import Telegram from "../../../assets/images/telegram.jpg";
import Discord from "../../../assets/images/discord.svg";
import { toastify } from "../../../helpers/toast/toastify";
import { Link, useNavigate } from "react-router-dom";
import RenderLinks from "../../../components/links/RenderLinks";
import {
  setShowSidebar,
  setSidebar,
} from "../../../redux/sidebar/sidebarSlice";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { setCurrentTab } from "../../../redux/links/linksSlice";
import { updateUser } from "../../../redux/users/usersSlice";

function Url() {
  const [community, setCommunity] = useState();
  const user = useSelector((value) => value?.user?.user);
  const { showSidebar } = useSelector((state) => state.sidebar);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const api = useApi();
  useEffect(() => {
    if (user?.leaderCommunity) {
      setCommunity(user?.leaderCommunity);
    }
  }, [user]);

  const handleSocial = () => {
    navigate("/leader/performance", { replace: true });
  };
  const handleUpdate = async () => {
    dispatch(setShowSidebar(true));
    dispatch(setSidebar(false));
    dispatch(setCurrentTab(""));

    if (user?.role === "member") {
      const response = await api("post", "users/update", {
        onboardingStep: "",
        firstVisit: false,
      });
      navigate("/member/dashboard", { replace: true });
      dispatch(updateUser(response?.data?.user));
    } else {
      const response = await api("post", "users/update", {
        onboardingStep: "",
        firstVisit: false,
      });
      navigate("/leader/dashboard", { replace: true });
      dispatch(updateUser(response?.data?.user));
    }
  };

  const sendMailToThomas = async () => {
    await api("post", "auth/sendMailToThomas", {
      user,
    });
  };

  return (
    <React.Fragment>
      <div className="url d-flex flex-column">
        <div className="title">
          <h3 style={{ marginBottom: "10px" }}>
            Your copy trade service is live!
          </h3>
          {/* <p>
                        Everything you trade from the connected account your
                        subscribers will trade too! Simple, really.
                    </p> */}
          <p className="mt-3">
            This is the link for your unique landing page for members to sign
            up.
          </p>
        </div>
        <div className="connectionBlock">
          <div className="position-relative d-flex align-items-center justify-content-end copyBlock">
            <input
              type="text"
              className="form-control fs14"
              value={`${document.location.origin.replace(
                document.location.pathname,
                ""
              )}/community/${user?.leaderCommunity?.name
                ?.split(" ")
                ?.join("")}`}
              disabled
            />
            <span className="position-absolute">
              <img
                className="cursor-pointer"
                onClick={() => {
                  // Construct the community link using template literals and optional chaining
                  const communityLink = `${document.location.origin.replace(
                    document.location.pathname,
                    ""
                  )}/community/${user?.leaderCommunity?.name
                    ?.split(" ")
                    ?.join("")}`;

                  // Attempt to copy the link to the clipboard
                  navigator.clipboard
                    .writeText(communityLink)
                    .then(() => {
                      // Success: Show a toast notification
                      toastify(
                        "Community link copied to clipboard!",
                        "success"
                      );
                    })
                    .catch((error) => {
                      // Error: Handle clipboard write failure and show a toast notification
                      console.error("Clipboard write error:", error);
                      toastify(
                        "Failed to copy community link to clipboard.",
                        "error"
                      );
                    });
                }}
                src={copyIcon}
              />
            </span>
          </div>
        </div>
        {/* <div className="haveSeen">
                    <div className="title">
                        <h3>Have you seen this?</h3>
                    </div>
                    <div className="haveSeenCards">
                        <div className="card">
                            <h4>Auto share trade results</h4>
                            <div className="blocks">
                                <div className="text">
                                    <p>
                                        Automatically share performance to your
                                        social channels.
                                    </p>
                                    <button
                                        className="black-btn text_GM"
                                        onClick={() =>
                                            navigate("/leader/performance", {
                                                state: { tab: "autoShare" },
                                            })
                                        }
                                    >
                                        Set it up
                                    </button>
                                </div>
                                <div className="socialIcons">
                                    <div className="block">
                                        <img src={X} alt="X" />
                                    </div>
                                    <div className="block">
                                        <img src={Telegram} alt="Telegram" />
                                        <img src={Discord} alt="Discord" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <h4>Create a promotion</h4>
                            <div className="blocks">
                                <div className="text">
                                    <p>
                                        Offer a discount when you promote your
                                        copy trade
                                    </p>
                                    <button
                                        className="black-btn text_GM"
                                        onClick={() =>
                                            navigate("/leader/performance", {
                                                state: {
                                                    tab: "createPromotion",
                                                },
                                            })
                                        }
                                    >
                                        Create
                                    </button>
                                </div>
                                <img
                                    src={Discount}
                                    className="discountImg"
                                    alt="Discount"
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
        {!showSidebar && (
          <div className="d-flex justify-content-center">
            <button
              style={{ width: "294px" }}
              className="newCreateButton"
              onClick={() => {
                sendMailToThomas();
                handleUpdate(); // Call the handleUpdate function
              }}
            >
              Go to dashboard
            </button>
          </div>
        )}
        <RenderLinks page="copyTradeUrl" />
      </div>
    </React.Fragment>
  );
}

export default Url;

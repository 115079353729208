import React from "react";
import "./LeaderDashboard2.css";
import DashboardStats2 from "../../components/dashboard-stats/DashboardStats2";
import OpenTrades from "../../components/dashboard-stats/OpenTrades";

const LeaderDashBoard2 = () => {
  return (
    <>
      <div className="mainDashboardPage">
        <div className="container-fluid g-0">
          <div className="row">
            <div className="col-12">
              <div className="dashboardStatusBox d-lg-none d-md-none d-block text-end mt-4">
                <p>Status: Trading</p>
                <p>Connected to: Binance</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 col-md-6 col-12">
              <div className="dashboardStatusBox d-lg-block d-md-block d-none">
                <p>Status: Trading</p>
              </div>
              <DashboardStats2 role="leader" />
            </div>
            <div className="col-lg-1 col-md-1 col-12">
              <div className="d-flex h-100 align-items-center justify-content-center">
                <span className="minddleBorder"></span>
              </div>
            </div>
            <div className="col-lg-4 col-md-5 col-12">
              <div className="dashboardStatusBox justify-content-end d-lg-flex d-md-flex d-none">
                <p>Connected to: Binance</p>
              </div>
              <div className="d-none d-lg-block d-md-block padingTop">
                <OpenTrades />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaderDashBoard2;

// import React from "react";
// import { Outlet } from "react-router-dom";
// import SideBar from "./SideBar";
// import "./NewLayout.css"

// const NewLayout = () => {
//   return (
//     <>
//       <div className="newLayoutBox">
//         <div className="sideBarBox">
//           <SideBar />
//         </div>
//         <div className="mainContentBox">
//           <Outlet />
//         </div>
//       </div>
//     </>
//   );
// };

// export default NewLayout;



import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "./SideBar";
import "./NewLayout.css";

const NewLayout = () => {
  return (
    <div className="newLayoutBox">
      <div className="sideBarBox">
        <SideBar newLayout={true} />
      </div>
      <div className="mainContentBox">
        <Outlet />
      </div>
    </div>
  );
};

export default NewLayout;

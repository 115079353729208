import React from "react";
import close_danger from "../../assets/images/close_danger.svg";

export default function DeleteMember({
  text,
  noAction,
  yesAction,
  subtext,
  anotherText,
  noneclass,
  no,
  yes,
}) {
  return (
    <div className="modal fade show zoomed">
      <div className="modal-dialog modal-sm modal-dialog-centered">
        <div className="modal-content" style={{ marginTop: "20vh" }}>
          <div className="modal-body p-0 position-relative deleteMember">
            <div className="mx-5 mt-4">
              <p className="fs14 text-700 text-dark delete-member-text">
                {text || ""}
              </p>
              <p className="delete-member-subtext">{subtext || ""}</p>
              <p className="delete-member-subtext">{anotherText || ""}</p>
            </div>
            <div className="d-flex mx-5 mb-4 mt-2">
              <button className="cyan-btn " onClick={yesAction}>
                {yes || "Ok"}
              </button>
              <div className={`redBtn ms-2 ${noneclass}`} onClick={noAction}>
                <button className="red-btn d-flex align-items-center justify-content-center">
                  {no || "Cancel"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

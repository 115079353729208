import React, { useEffect, useState } from "react";
// import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { useDispatch, useSelector } from "react-redux";
import { storeUser, updateUser } from "../../../redux/users/usersSlice";
import copyIcon from "../../../assets/images/Copy.svg";
import Cross from "../../../assets/images/cross.svg";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { setLoading } from "../../../redux/loading/loadingSlice";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/button/Button";
import AddDiscordModal from "../../../components/modals/AddDiscordModal";

const config = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export default function LeaderSubscriptions() {
  const api = useApi();
  const { id } = useParams();
  const [community, setCommunity] = useState();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((value) => value?.user?.user);
  const [tagData, setTagData] = useState([]);
  const numberRegex = new RegExp(`^[0-9]*$`);

  useEffect(() => {
    if (user?.leaderCommunity) {
      setCommunity(user?.leaderCommunity);
      setTagData(user?.leaderCommunity?.subscription?.pair);
    }
  }, [user]);

  useEffect(() => {
    if (!user?.onboarding?.subscribed && user?.firstVisit && !id) {
      toastify(
        "Please ensure your subscription plan is as you want it",
        "success"
      );
      // setShow(true);
    }
  }, [user?.onboarding?.subscribed, user?.firstVisit]);

  const removeTagData = (indexToRemove) => {
    setTagData([...tagData.filter((_, index) => index !== indexToRemove)]);
  };
  const addTagData = (event) => {
    if (event.target.value !== "") {
      setTagData([...tagData, event.target.value]);
      event.target.value = "";
    }
  };

  const handleInputChange = (value, key) => {
    setCommunity(() => {
      let newValues = { ...community.subscription };
      if (key === "name") newValues = { ...newValues, name: value };
      else if (key === "price") newValues = { ...newValues, price: value };
      else if (key === "roi") newValues = { ...newValues, roi: value };
      else if (key === "estimatedAmount")
        newValues = {
          ...newValues,
          estimatedAmount: value,
        };
      else if (key === "minFund") newValues = { ...newValues, minFund: value };
      else if (key === "recommendPercentage")
        newValues = {
          ...newValues,
          recommendPercentage: value || 1,
        };
      else if (key === "hideStats")
        newValues = { ...newValues, hideStats: value };
      else if (key === "pair") {
        newValues = { ...newValues, pair: [...tagData, value] };
      }
      return { ...community, subscription: newValues };
    });
  };

  const submit = async () => {
    dispatch(setLoading(true));
    // Validate input fields
    if (
      !community?.subscription?.name ||
      !community?.subscription?.price ||
      !community?.subscription?.roi ||
      !community?.subscription?.estimatedAmount ||
      !community?.subscription?.minFund ||
      !community?.subscription?.recommendPercentage ||
      community?.subscription?.pair.length === 0
    ) {
      toastify("Please fill in all required fields.", "error");
      dispatch(setLoading(false));
      return;
    }

    const response = await api("post", "users/submitSubscription", {
      subscription: community?.subscription,
    });

    if (response?.status) {
      const response2 = await api("get", "users/index");
      if (response2) {
        toastify("Subscription modified successfully.", "success");
        dispatch(updateUser(response2?.data?.user));
        if (
          response2?.data?.user?.onboarding?.subscribed &&
          !response2?.data?.user?.trialVersion &&
          response2?.data?.user?.firstVisit
        ) {
          navigate("/leader/getPaid");
        } else if (user?.firstVisit && user?.trialVersion) {
          navigate("/leader/connectExchange");
        }
      }
    } else {
      toastify(response?.message);
    }
    dispatch(setLoading(false));
  };

  const uploadFile = async (file) => {
    dispatch(setLoading(true));
    let fd = new FormData();
    fd.append("file", file);
    let response = await config.post("community/uploadFile", fd);
    dispatch(setLoading(false));

    let url = response?.data?.data;
    if (url) {
      let subscription = { ...community.subscription, logo: url };
      setCommunity({ ...community, subscription });
    } else {
      toastify("Unable to upload logo. Please contact support.");
    }

    dispatch(setLoading(false));
  };

  return (
    <>
      {/* {show && (
                <AddDiscordModal
                    noAction={() => {
                        setShow(false);
                    }}
                    yesAction={handleAccept}
                />
            )} */}
      <div className="row justify-content-center h-100 px-5">
        <div className="col-lg-10 disable_animation_accordion d-flex flex-column">
          <div className="page_title d-sm-flex align-items-sm-center justify-content-sm-between">
            <h4 className="fs25 text_GB">Define your copy trade service</h4>
          </div>
          <div className="h-100 d-flex flex-column">
            <div className="page_form gap-4 mt-sm-2 mt-1 pt-sm-2 pt-1">
              <p className="fs16 mb-2">
                Here you define the details of your copy trade service you want
                to offer to your community.
              </p>
              <p className="fs16 mb-3">
                Once your setup is all done you’ll receive a URL for your copy
                trade service which you can share with your fans.
              </p>
              <React.Fragment>
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-8 mt-4 d-flex flex-column justify-content-end">
                    <label className="fs15">
                      What do you call your service?
                    </label>
                    <input
                      type="text"
                      className="form-control fs15 mt-1"
                      placeholder="Name your subscription plan"
                      value={community?.subscription?.name || ""}
                      onChange={(val) =>
                        handleInputChange(val.target.value, "name")
                      }
                    />
                  </div>
                  <div className="col-lg-12 pt-lg-1 mt-2 mt-4 pt-1 d-flex flex-column justify-content-end">
                    <div className="row">
                      <div className="col-6 mt-auto">
                        <label className="fs15">
                          What do you charge monthly?
                        </label>
                        <input
                          type="text"
                          className="form-control fs15 mt-1"
                          placeholder="$"
                          value={community?.subscription?.price || ""}
                          onChange={(val) => {
                            if (
                              numberRegex.test(val.target.value) ||
                              val.target.value === ""
                            ) {
                              handleInputChange(val.target.value, "price");
                            }
                          }}
                        />
                      </div>
                      <div className="col-6 mt-auto">
                        <label className="fs15">Monthly ROI (approx)</label>
                        <input
                          type="text"
                          className="form-control fs15 mt-1"
                          placeholder="%"
                          value={community?.subscription?.roi || ""}
                          onChange={(val) => {
                            if (
                              numberRegex.test(val.target.value) ||
                              val.target.value === ""
                            ) {
                              handleInputChange(val.target.value, "roi");
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-2 mt-4 pt-lg-1 pt-1">
                    <div className="row">
                      <div className="col-12 d-flex flex-column justify-content-end">
                        <label className="fs15">
                          The last 3 months your service would have turned $1000
                          into?
                        </label>
                        <input
                          type="text"
                          className="form-control fs15 mt-1"
                          placeholder="$"
                          value={community?.subscription?.estimatedAmount || ""}
                          onChange={(val) => {
                            if (
                              numberRegex.test(val.target.value) ||
                              val.target.value === ""
                            ) {
                              handleInputChange(
                                val.target.value,
                                "estimatedAmount"
                              );
                            }
                          }}
                        />
                      </div>
                      {/* <div className="col-6 d-flex flex-column justify-content-end">
                                                <label className="fs15">
                                                    How many trades?
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control fs15 mt-1"
                                                    placeholder="Approx"
                                                    value={
                                                        community?.subscription
                                                            ?.numberOfTrades ||
                                                        ""
                                                    }
                                                    onChange={(val) => {
                                                        if (
                                                            numberRegex.test(
                                                                val.target.value
                                                            ) ||
                                                            val.target.value ===
                                                                ""
                                                        ) {
                                                            handleInputChange(
                                                                val.target
                                                                    .value,
                                                                "number"
                                                            );
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6 d-flex flex-column justify-content-end">
                                                <label className="fs15">
                                                    How often?
                                                </label>
                                                <select
                                                    className="w-100 form-control mt-1"
                                                    value={
                                                        community?.subscription
                                                            ?.frequency || ""
                                                    }
                                                    onChange={(val) => {
                                                        handleInputChange(
                                                            val.target.value,
                                                            "frequency"
                                                        );
                                                    }}
                                                >
                                                    <option
                                                        value={""}
                                                        style={{
                                                            display: "none",
                                                        }}
                                                    >
                                                        Select
                                                    </option>
                                                    <option value="daily">
                                                        Daily
                                                    </option>
                                                    <option value="weekly">
                                                        Weekly
                                                    </option>
                                                    <option value="monthly">
                                                        Monthly
                                                    </option>
                                                </select>
                                            </div> */}
                      <div className="col-12 mt-4 d-flex flex-column justify-content-end">
                        <label className="fs15">
                          What are you mainly trading? Fx. Alts/USDT+enter
                        </label>
                        <div className="tag-input mt-1">
                          <ul className="tags">
                            {tagData.map((tag, index) => (
                              <li key={index} className="tag">
                                <span className="tag-title">{tag}</span>
                                <span
                                  className="tag-close-icon"
                                  onClick={() => removeTagData(index)}
                                >
                                  <img src={Cross} alt="cross" />
                                </span>
                              </li>
                            ))}
                          </ul>
                          <input
                            type="text"
                            className="fs15"
                            onKeyUp={(event) =>
                              event.key === "Enter" ? addTagData(event) : null
                            }
                            // placeholder="Press add a currency"
                            onChange={(val) => {
                              handleInputChange(val.target.value, "pair");
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-12 d-flex flex-column justify-content-end mt-4">
                        <label className="fs15">
                          What min. fund size is necessary for your subs?
                        </label>
                        <input
                          type="text"
                          className="form-control fs15 mt-1"
                          placeholder="$"
                          value={community?.subscription?.minFund || ""}
                          onChange={(val) => {
                            if (
                              numberRegex.test(val.target.value) ||
                              val.target.value === ""
                            ) {
                              handleInputChange(val.target.value, "minFund");
                            }
                          }}
                        />
                      </div>

                      <div className="col-12 d-flex flex-column justify-content-end mt-4">
                        <label className="fs15">
                          Whats your recommended % size of portfolio per trade?
                        </label>
                        <select
                          className="w-100 form-control mt-1"
                          value={
                            community?.subscription?.recommendPercentage || ""
                          }
                          onChange={(val) => {
                            handleInputChange(
                              val.target.value,
                              "recommendPercentage"
                            );
                          }}
                        >
                          <option value={""} disabled>
                            %
                          </option>
                          <option value={"1"}>1%</option>
                          <option value={"2"}>2%</option>
                          <option value={"3"}>3%</option>
                          <option value={"4"}>4%</option>
                          <option value={"5"}>5%</option>
                        </select>
                      </div>

                      <div className="col-12 mt-4">
                        <label className="fs15">
                          Your landing page for your copy trade service
                        </label>
                        <div className="mt-2 col-sm-12 col-lg-12 col-md-12 position-relative d-flex align-items-center justify-content-end">
                          <input
                            type="text"
                            className="form-control fs15 bold"
                            value={`${document.location.origin.replace(
                              document.location.pathname,
                              ""
                            )}/community/${user?.leaderCommunity?.name
                              ?.split(" ")
                              ?.join("")}`}
                            disabled
                          />
                          <span className="position-absolute me-2">
                            <img
                              className="cursor-pointer me-2"
                              onClick={() => {
                                // Construct the community link using template literals and optional chaining
                                const communityLink = `${document.location.origin.replace(
                                  document.location.pathname,
                                  ""
                                )}/community/${user?.leaderCommunity?.name
                                  ?.split(" ")
                                  ?.join("")}`;

                                // Attempt to copy the link to the clipboard
                                navigator.clipboard
                                  .writeText(communityLink)
                                  .then(() => {
                                    toastify(
                                      "Community link copied to clipboard!",
                                      "success"
                                    );
                                  })
                                  .catch((error) => {
                                    // Error: Handle clipboard write failure and show a toast notification
                                    console.error(
                                      "Clipboard write error:",
                                      error
                                    );
                                    toastify(
                                      "Failed to copy community link to clipboard.",
                                      "error"
                                    );
                                  });
                              }}
                              src={copyIcon}
                            />
                          </span>
                        </div>
                      </div>

                      <div className="col-12 d-flex flex-column justify-content-end mt-4">
                        <label className="fs15">
                          Hide statistics on the landing page
                        </label>
                        <input
                          type="checkbox"
                          className="mt-1"
                          checked={community?.subscription?.hideStats || false}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            handleInputChange(checked, "hideStats");
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-4 pt-lg-1 pt-1">
                      <div className="col-8 col-sm-6 d-flex flex-column justify-content-end">
                        <label className="fs15">Add your logo here</label>

                        {community?.subscription?.logo ? (
                          <>
                            <img
                              src={community?.subscription?.logo}
                              alt="logo"
                              className="community-logo-uploading"
                            />
                            <p
                              onClick={() => {
                                let subscription = {
                                  ...community.subscription,
                                  logo: "",
                                };
                                setCommunity({
                                  ...community,
                                  subscription,
                                });
                              }}
                              className="cursor-pointer bold text-underline mt-3"
                            >
                              Change Logo
                            </p>
                          </>
                        ) : (
                          <input
                            type="file"
                            className="form-control fs15 mt-1 w-auto"
                            placeholder="No file"
                            // value={community?.subscription?.logo || null}
                            onChange={(val) => {
                              if (val.target.files[0]) {
                                uploadFile(val.target.files[0]);
                              }

                              // handleInputChange(
                              //     val.target.value,
                              //     "logo"
                              // );
                            }}
                          />
                        )}
                      </div>
                    </div>

                    {/* <div className="mt-4 pt-lg-1 pt-1">
                                            <div className="col-12 d-flex flex-column justify-content-end">
                                                <label className="fs15">
                                                    Do you want to create a
                                                    Discord channel? Click the
                                                    button below.
                                                </label>
                                                <div className="mt-4">
                                                    <Button
                                                        name="CREATE"
                                                        handleClick={
                                                            handleDiscordClick
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </React.Fragment>

              <div className="mt-4 d-grid gap-3">
                <p>
                  Please remember that we add $10 to your subscription fee. Your
                  users will only see one total amount (your fee + ours).
                </p>
                <p>
                  If you cancel your service all together your subs will be
                  cancelled immediately and no refund made.
                </p>
              </div>
            </div>
            <div className="mt-5 mb-4">
              <Button name="SAVE" handleClick={submit} />
              {/* <button
              className="btn btn-success radius min_h57 w_261 fs20"
              onClick={submit}
            >
              SAVE
            </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

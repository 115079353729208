import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useApi from "../../helpers/apiHelper/requestHelper";
import Button from "../../components/button/Button";
import { useRef, useState } from "react";
import edit_icon from "../../assets/images/edit_icon.svg";
import { setLoading } from "../../redux/loading/loadingSlice";
import { toastify } from "../../helpers/toast/toastify";
import { updateUser } from "../../redux/users/usersSlice";

export default function LeaderCryptoPayment() {
  const navigate = useNavigate();
  const api = useApi();
  const user = useSelector((state) => state?.user?.user);
  const walletRef = useRef(null);

  // Initialize the walletData state with the structure matching your database model
  const [walletData, setWalletData] = useState({
    walletInfo: {
      usdtWalletAddress: user?.usdtWalletAddress || "", // Initialize with the user's existing value
      exchange: "", // Initialize with an empty value
    },
  });

  const dispatch = useDispatch();

  // Function to handle changes in the wallet address input
  const handleWalletChange = (e) => {
    const { name, value } = e.target;
    setWalletData((prevData) => ({
      ...prevData,
      walletInfo: {
        ...prevData.walletInfo,
        [name]: value,
      },
    }));
  };

  // Function to handle changes in the exchange select
  const handleExchangeChange = (e) => {
    const { value } = e.target;
    setWalletData((prevData) => ({
      ...prevData,
      walletInfo: {
        ...prevData.walletInfo,
        exchange: value,
      },
    }));
  };

  const handleUpdate = async () => {
    const { usdtWalletAddress, exchange } = walletData?.walletInfo;

    if (!usdtWalletAddress.trim() && !exchange.trim()) {
      toastify("Wallet address & exchange cannot be empty!");
      return;
    }

    // Dispatch the action to update the user's profile in Redux and make the API request
    dispatch(setLoading(true));
    const response = await api("post", "users/update", {
      walletInfo: {
        usdtWalletAddress,
        exchange,
      },
    });
    if (response?.status) {
      dispatch(setLoading(false));

      dispatch(updateUser(response?.data?.user));
      toastify("Wallet address added successfully!", "success");
      navigate("/leader/connectExchange");
    } else {
      dispatch(setLoading(false));

      toastify(response?.message);
    }
  };

  return (
    <>
      <div className="get_paid" style={{ padding: "0px 36px" }}>
        <section>
          <div>
            {user?.firstVisit && (
              <h4 className="community_name mb-5 me-5">
                {user?.leaderCommunity?.name}
              </h4>
            )}
          </div>
          <div className="mb-5">
            <p className="main_heading">
              WALLET <br /> ADDRESS <br /> TO RECEIVE <br /> YOUR <br />{" "}
              PAYMENTS
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-center mb-5">
            <div className="mb-3">
              <h4 className="trade_heading">Your USDT wallet address</h4>
              <p className="fs16">Please double check that it's:</p>
              <ul className="ms-3 mb-3 fs16">
                <li>The correct USDT wallet address</li>
                <li>Using TRX network</li>
              </ul>

              <div className="col-10">
                <div>
                  <input
                    ref={walletRef}
                    type="text"
                    className="form-control fs15 mt-1 mb-2"
                    name="usdtWalletAddress"
                    placeholder={"Enter wallet address"}
                    value={walletData?.walletInfo?.usdtWalletAddress}
                    onChange={handleWalletChange}
                  />
                </div>
                <div>
                  <select
                    className="w-100 form-control"
                    name="exchange"
                    value={walletData.walletInfo.exchange}
                    onChange={handleExchangeChange}
                  >
                    <option value="" style={{ display: "none" }}>
                      Select your exchange
                    </option>
                    <option value="Binance">Binance</option>
                    <option value="ByBit">ByBit</option>
                  </select>
                </div>
              </div>

              <div className="col-8 note mt-5">
                <h4 className="note_msg bold">
                  {" "}
                  Can I change the address later?
                </h4>
                <p className="note_msg">
                  Great question! Yes, you can change it in ‘Subscription’ in
                  the dashboard menu once you’re logged in. Easy!
                </p>
              </div>
            </div>
          </div>
          <Button
            name="Next"
            handleClick={() => {
              handleUpdate();
            }}
          />
        </section>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import CustomPagination from "../../../components/CustomPagination";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { useSelector } from "react-redux";

const LiveTrade = () => {
  const [page, setPage] = useState(1);
  const [openPosition, setOpenPosition] = useState([]);
  const [activeOrders, setActiveOrders] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const user = useSelector((value) => value?.user?.user);
  const isWaleed = user?.email === "parishaybintewaleed@gmail.com";

  const api = useApi();

  const callApi = async () => {
    const response = await api("post", "livetrade/getLiveTrade");
    if (response?.data) {
      const data = response.data;

      const openPositions = data?.[0]?.openPositions || [];
      setOpenPosition(openPositions);

      const activeOrders = data?.[1]?.activeOrders || [];
      setActiveOrders(activeOrders);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Check the screen size on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    callApi();
  }, []);

  const createSUb = async () => {
    await api("post", "SubAccount/createbinanceAccounts");
  };

  return (
    <div className="container">
      <div className="row justify-content-center support_wrapper h-100">
        <div className="tab-content">
          <div className="row justify-content-center h-100 p-top-on-mobile">
            <div className="col-lg-12">
              <div
                className="connection"
                style={{ paddingTop: isMobile ? "0px" : "42px" }}
              >
                <div className="mx-auto mt-30">
                  <div className="page_title d-flex align-items-center justify-content-between flex-nowrap mb-3">
                    <h2 className="tradinghistporyTitle mt-30">Open Trades</h2>
                    {isWaleed && (
                      <button onClick={createSUb}> Create SUb Account</button>
                    )}
                  </div>

                  <div className="tradeHistory">
                    <Table striped className="billingTable TradeHistoryTable">
                      <thead>
                        <tr>
                          <th className="text-start ps-3">
                            Asset{" "}
                            <span className="d-lg-none d-md-none">
                              & Direction
                            </span>
                          </th>
                          <th className="text-start d-set">Direction</th>
                          <th>Open</th>
                          <th className="w-lg-200px pe-3">PNL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {openPosition.length > 0 ? (
                          openPosition
                            ?.filter((e, index) => {
                              if (index < page * 10 && index > page * 10 - 11) {
                                return e;
                              }
                            })
                            ?.map((position, index) => {
                              const createdTime = new Date(
                                parseInt(
                                  position.createdTime || position.updateTime
                                )
                              );

                              const formattedTime = createdTime.toLocaleString(
                                "en-US",
                                {
                                  hour12: false, // This ensures the time is in 24-hour format
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                }
                              );

                              return (
                                <tr key={index}>
                                  <td className="text-start ps-3">
                                    <p className="border-right text-truncate pb-set">
                                      {position?.symbol}
                                    </p>
                                    <p className="d-lg-none d-md-none pt-0">
                                      {position?.side}
                                    </p>
                                  </td>
                                  <td className="text-start d-set">
                                    <p className="border-right">
                                      {position?.side}
                                    </p>
                                  </td>
                                  <td className="dark-text">
                                    <p>
                                      <span>{formattedTime}</span>
                                    </p>
                                  </td>
                                  <td
                                    className={`w-lg-200px pe-3 numbers ${
                                      position.pnlPercentage < 0 ? "red" : ""
                                    }`}
                                  >
                                    {position.pnlPercentage?.toFixed(2)}%
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <tr>
                            <td colSpan="4" className="text-center">
                              No open positions found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>

                  <div className="tradeHistoryMbl d-none"></div>

                  <div className="d-flex justify-content-end mt-41">
                    <CustomPagination
                      setPage={setPage}
                      page={page}
                      filtered={openPosition}
                      items={openPosition}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveTrade;

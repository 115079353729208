import React, { useState } from "react";
import AddMember from "./AddMember/AddMember";
import MemberList from "./MemberList/MemberList";

const VipScreen = () => {
  const [refetch, setRefetch] = useState(false);

  return (
    <div className="mainVipMemberPage">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mt-smLayout-30">
            <div className="PageHeading">
              <h2 className="text_GB fs20 mt-smLayout-30">VIP Members</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <AddMember setRefetch={setRefetch} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <MemberList refetch={refetch} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VipScreen;

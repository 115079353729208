import React, { useEffect, useState } from "react";
import searchIcon from "../../../assets/images/newSearchIcon.svg";
import { useDetectClickOutside } from "react-detect-click-outside";
// import Pagination from "../../../components/Pagination";
import CustomPagination from "../../../components/CustomPagination";
import Cross from "../../../assets/images/cancel.svg";
import Message from "../../../assets/images/Envelope.svg";
import DeleteMember from "../../../components/modals/DeleteMember";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { updateUser } from "../../../redux/users/usersSlice";
import { setLoading } from "../../../redux/loading/loadingSlice";
import { toastify } from "../../../helpers/toast/toastify";
import RenderLinks from "../../../components/links/RenderLinks";
import NoDataGif from "../../../assets/images/no_data.gif";
import { simplifyTradingVolume } from "../../../helpers/helper";
import { Table } from "react-bootstrap";

export default function MembersOverview({ setTab, setUser }) {
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("name");
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [page, setPage] = useState(1);
  const user = useSelector((value) => value?.user?.user);
  const api = useApi();
  const [stats, setStats] = useState({});
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTradingCapital, setSelectedTradingCapital] = useState(null);
  const [clickVip, setClickVip] = useState(false);

  const participants = useSelector(
    (state) => state?.user?.user?.leaderCommunity?.participants
  );

  const ref = useDetectClickOutside({
    onTriggered: () => {
      setShow(false);
      setSearchTerm("");
      setSelectedTradingCapital(null);
      setAllSubscribersFromCommunity(participants);
      setClickVip(false);
    },
  });

  const [allSubscribersFromCommunity, setAllSubscribersFromCommunity] =
    useState(participants);

  const subscriptions = useSelector(
    (state) => state?.user?.user?.leaderCommunity?.subscription
  );
  const leaderCommunity = useSelector(
    (state) => state?.user?.user?.leaderCommunity
  );

  const subscriptionss = participants.map((participant) => {
    const user = participant.user;
    if (user && user.memberCommunities) {
      // Assuming you're interested in the first member community for each user
      const memberCommunity = user.memberCommunities[0];
      return memberCommunity ? memberCommunity.subscription : null;
    }
    return null;
  });

  const tradingCapitals = subscriptionss.map((subscriptionId) => {
    const matchedSubscription = subscriptions.find(
      (sub) => sub._id === subscriptionId
    );
    return matchedSubscription ? matchedSubscription.tradingCapital : null;
  });

  const allTradingCapital = subscriptions.map(
    (subscription) => subscription.tradingCapital
  );

  const vipSubscription = subscriptions.filter((sub) =>
    sub.name.endsWith("_Vip")
  )[0];

  if (vipSubscription) {
    allTradingCapital.push("VIP");
  }

  const getUserStats = async () => {
    const response = await api("get", "stats/index");
    if (response?.status) {
      setStats(response?.data);
    }
  };

  useEffect(() => {
    const fetchBalances = async () => {
      const response = await api("get", "users/getMembersBalance");
      if (response?.status) {
        dispatch(updateUser(response?.data));
      }
      setFilterData(response?.data?.leaderCommunity?.participants);
    };

    fetchBalances();
    getUserStats();
  }, []);

  useEffect(() => {
    if (!Array.isArray(participants)) {
      return;
    }
    let filtered = [...participants];

    filtered = filtered.filter((e) =>
      e?.user?.email?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );
    setFilteredParticipants(filtered);
  }, [searchTerm, participants]);

  const noAction = () => {
    setShowModal(false);
  };

  const handleMessage = (val) => {
    setTab("message");
    setUser(val);
  };

  const yesAction = async () => {
    setShowModal(false);
    dispatch(setLoading(true));
    const response = await api("post", "users/unsubscribe", {
      sessionId: selectedSubscription,
      communityId: leaderCommunity._id,
      userId: selectedUser,
      leader: true,
    });
    if (response?.status) {
      toastify("User unsubscribed successfully.", "success");
      dispatch(updateUser(response?.data?.user));
    } else {
      toastify(response?.message);
    }

    dispatch(setLoading(false));

    setSelectedSubscription("");
    setSelectedUser("");
  };

  const handleTradingCapitalClick = (trading) => {
    if (trading !== "VIP") {
      setClickVip(false);
      setSelectedTradingCapital(trading);
    } else {
      setSelectedTradingCapital(null);
      setClickVip(trading);
      const VIPUsers = participants.filter((item) =>
        item.user.memberCommunities.some(
          (community) => community.isSubscribedVipPlan === true
        )
      );
      setAllSubscribersFromCommunity(VIPUsers);
    }
  };

  useEffect(() => {
    console.log("force fully rerender");
  }, [allSubscribersFromCommunity]);

  return (
    <div className="row justify-content-center h-100">
      <div className="col-lg-12 d-flex flex-column">
        <div className="connection">
          <div className="py-40">
            <div className="subscriptionMember-topStats">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 mb-4 mb-lg-0 mb-md-0">
                  <div className="subscriberCard">
                    <div className="row align-items-center align-item-lg-start align-item-md-start">
                      <div className="col-lg-8 col-8">
                        <p className="title">Total Subscribers</p>
                        <p className="desc">
                          <span className="green-text me-1">
                            +{stats?.userGrowthInWeekNumber || 0} Subs
                          </span>
                          since last week
                        </p>
                      </div>
                      <div className="col-lg-4 col-4">
                        <p className="numbers text-end text-lg-center text-md-center">
                          {user?.leaderCommunity?.participants?.length || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="subscriberCard">
                    <div className="row align-items-center align-item-lg-start align-item-md-start">
                      <div className="col-lg-8 col-8">
                        <p className="title">Avg. membership</p>
                        <p className="desc">In months</p>
                      </div>
                      <div className="col-lg-4 col-4">
                        <p className="numbers text-end text-lg-center text-md-center">
                          {stats?.membersMonthAvg || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="title member-heaidng d-flex justify-content-between d-sm-block">
              <h3>Members</h3>
              <div className="page_title d-flex align-items-center justify-content-end flex-nowrap mb-3">
                <div className="custom-dropdown d-flex" ref={ref}>
                  <button
                    className="dropdown-toggle position-unset"
                    onClick={() => setShow(!show)}
                  >
                    <img
                      className="filter-icon"
                      alt="searchIcons"
                      src={searchIcon}
                    />
                  </button>

                  {show && (
                    <div className="dropdown-menu">
                      <div className="membersSearch">
                        <div className="tabs">
                          <button
                            className={`tab ${
                              activeTab === "name" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("name")}
                          >
                            Email
                          </button>
                          <button
                            className={`tab ${
                              activeTab === "tier" ? "active" : ""
                            }`}
                            onClick={() => setActiveTab("tier")}
                          >
                            Tier
                          </button>
                        </div>
                        {activeTab === "name" && (
                          <div>
                            {participants?.length > 0 ? (
                              <div className="tab-contents">
                                <input
                                  type="text"
                                  placeholder="Search email"
                                  value={searchTerm}
                                  onChange={(e) =>
                                    setSearchTerm(e.target.value)
                                  }
                                />
                              </div>
                            ) : (
                              <div className="ps-2 pe-sm-5 pe-2 py-3 text-center">
                                <h6 className="bold">No Subscribers</h6>
                              </div>
                            )}
                          </div>
                        )}

                        {activeTab === "tier" && (
                          <div className="tab-contents">
                            {allTradingCapital?.map((trading, index) => (
                              <p
                                key={index}
                                onClick={() =>
                                  handleTradingCapitalClick(trading)
                                }
                                style={{ cursor: "pointer" }}
                                className={`TierSelect ${
                                  selectedTradingCapital === trading ||
                                  clickVip === trading
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <span>Tier:</span>
                                {trading === "UNLIMITED"
                                  ? "UNLIMITED"
                                  : `$${trading}`}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="membersOverview">
              <Table striped className="billingTable">
                <thead>
                  <tr>
                    <th className="text-start ps-3">Email</th>
                    <th className="text-start">Portfolio</th>
                    <th className="text-start">Joined</th>
                    <th className="text-start">Tier</th>
                    <th className="text-start ps-3 w-lg-200px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {allSubscribersFromCommunity &&
                  allSubscribersFromCommunity
                    .filter((e) =>
                      e?.user?.email
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .filter((e, index) => {
                      // Filter by selected trading capital
                      const participantTrading = tradingCapitals[index];

                      const test =
                        !selectedTradingCapital ||
                        participantTrading === selectedTradingCapital;
                      console.log(test, "1st ", selectedTradingCapital);

                      return (
                        !selectedTradingCapital ||
                        participantTrading === selectedTradingCapital
                      );
                    })
                    .slice((page - 1) * 10, page * 10).length > 0 ? (
                    allSubscribersFromCommunity
                      .filter((e) =>
                        e?.user?.email
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .filter((e, index) => {
                        // Filter by selected trading capital
                        const participantTrading = tradingCapitals[index];

                        const test =
                          !selectedTradingCapital ||
                          participantTrading === selectedTradingCapital;
                        console.log(test, "2nd ", selectedTradingCapital);

                        return (
                          !selectedTradingCapital ||
                          participantTrading === selectedTradingCapital
                        );
                      })
                      .slice((page - 1) * 10, page * 10)
                      .map((filteredParticipant, index) => (
                        <tr key={filteredParticipant.user._id}>
                          <td className="text-start ps-3 light text-limit">
                            {filteredParticipant?.user?.email}
                          </td>
                          <td className="text-start light">
                            {`$${simplifyTradingVolume(
                              (filteredParticipant?.binanceBalance?.balance ||
                                0) +
                                (filteredParticipant?.binanceBalance
                                  ?.coinFutureBalance || 0)
                            )}`}
                          </td>
                          <td className="text-start">
                            {(() => {
                              const date = new Date(filteredParticipant.date);
                              return `${
                                date.getMonth() + 1
                              }.${date.getDate()}.${date.getFullYear()}`;
                            })()}
                          </td>
                          <td className="text-start">
                            {tradingCapitals[index] === "UNLIMITED"
                              ? "UNLIMITED"
                              : `$${tradingCapitals[index]}`}
                          </td>
                          <td className="text-start d-display ps-3">
                            <button
                              type="button"
                              className="download-btn gapleft msj-btn"
                              onClick={() =>
                                handleMessage(filteredParticipant?.user)
                              }
                            >
                              <img src={Message} alt="message" />
                            </button>
                            <button
                              type="button"
                              className="download-btn gapright cros-btn"
                              onClick={() => {
                                const _community =
                                  filteredParticipant.user.memberCommunities?.find(
                                    (f) =>
                                      f.community ===
                                      leaderCommunity._id.toString()
                                  );
                                setSelectedSubscription(_community?.sessionId);
                                setSelectedUser(filteredParticipant.user._id);
                                setShowModal(true);
                              }}
                            >
                              <img src={Cross} alt="cross" />
                            </button>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No member Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>

            <div className="d-flex ms-auto justify-content-lg-end justify-content-md-end justify-content-end mt-41">
              <CustomPagination
                setPage={setPage}
                page={page}
                items={participants}
                filtered={filteredParticipants}
              />
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <DeleteMember
          noAction={noAction}
          yesAction={yesAction}
          text="Do you want to delete member?"
          subtext="The member won't be able to copy trade if deleted. We don't offer any refunds to the member. Still want to delete member?"
        />
      )}
      <RenderLinks page="membersOverview" />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useApi from "../../helpers/apiHelper/requestHelper";
import "./DashboardStats2.css";
import TradeGraph from "../../assets/images/graph.svg";
import { Link, useNavigate } from "react-router-dom";
import moreButton from "../../assets/images/MoreButton.svg";
import Discount from "../../assets/images/4.gif";
import mbls from "../../assets/images/mbls.png";
import DailyAvg from "../../assets/images/1.gif";
import X from "../../assets/images/x.jpg";
import Telegram from "../../assets/images/telegram.jpg";
import Discord from "../../assets/images/discord.svg";
import OpenTrades from "./OpenTrades";
import { simplifyTradingVolume } from "../../helpers/helper";
import { setCurrentTab } from "../../redux/links/linksSlice";
import Launch from "../../assets/images/2.gif";

const DashboardStats2 = ({ role }) => {
  const [stats, setStats] = useState({});
  const [yourTradingAccount, setYourTradingAccount] = useState(null);
  const [balanceSinceLastWeek, setBalanceSinceLastWeek] = useState(null);
  const [dailyAvgProfit, setDailyAvgProfit] = useState(null);
  const [averageProfitSinceLastWeek, setAverageProfitSinceLastWeek] =
    useState(null);
  const [winRate, setWinRate] = useState(null);
  const [avgTrade, setAvgTrade] = useState(null);
  const [totalSubscribers, setTotalSubscribers] = useState(0);
  const [userGrowthInWeekNumber, setUserGrowthInWeekNumber] = useState(null);
  const [lastTrade, setLastTrade] = useState(null);
  const [tradeAmount, setTradeAmount] = useState(null);
  const [totalTrades, setTotalTrades] = useState(null);
  const [performanceYTD, setPerformanceYTD] = useState(null);
  const [monthlyGrowth, setMonthlyGrowth] = useState(null);
  const [thisWeekPnlPercentage, setThisWeekPnlPercentage] = useState(null);
  const [growthSinceLastMonth, setGrowthSinceLastMonth] = useState(null);
  const [appLinks, setAppLinks] = useState(null);
  const [potentialTradingAccount, setPotentialTradingAccount] = useState(null);

  const user = useSelector((value) => value?.user?.user);
  const api = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getUserStats = async () => {
    const response = await api("get", "stats/index");
    if (response?.status) setStats(response?.data);
  };

  const updateUserBalance = async () => {
    let userExchange = "";

    if (user?.binanceCredentials?.isConnected) {
      userExchange = "Binance";
    } else if (user?.bybitCredentials?.isConnected) {
      userExchange = "ByBit";
    }

    await api("get", "stats/updateUserBalance", {
      id: user?._id,
      exchange: userExchange,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await updateUserBalance();
      await getUserStats();
    };
    fetchData();
  }, [user]);

  useEffect(() => {
    let tradingAccount = 0;

    if (user?.binanceCredentials?.isConnected) {
      tradingAccount = stats?.binanceBalance || 0;
    } else if (user?.bybitCredentials?.isConnected) {
      tradingAccount = stats?.bybitBalance || 0;
    }

    setTotalSubscribers(user?.leaderCommunity?.participants?.length);
    tradingAccount = simplifyTradingVolume(tradingAccount);
    tradingAccount =
      typeof tradingAccount === "string"
        ? tradingAccount
        : tradingAccount.toFixed(2);

    setYourTradingAccount(tradingAccount);

    let dailyAvgProfit = simplifyTradingVolume(stats?.dailyAvgProfit || 0);
    dailyAvgProfit =
      typeof dailyAvgProfit === "string"
        ? dailyAvgProfit
        : dailyAvgProfit.toFixed(2);

    setDailyAvgProfit(dailyAvgProfit);

    if (
      stats?.balanceSinceLastWeek !== undefined &&
      stats?.balanceSinceLastWeek !== 0
    ) {
      setBalanceSinceLastWeek(stats?.balanceSinceLastWeek?.toFixed(2));
    }

    if (
      stats?.averageProfitSinceLastWeek !== undefined &&
      stats?.averageProfitSinceLastWeek !== 0
    ) {
      let averageProfitSinceLastWeek = simplifyTradingVolume(
        stats?.averageProfitSinceLastWeek || 0
      );
      averageProfitSinceLastWeek =
        typeof averageProfitSinceLastWeek === "string"
          ? averageProfitSinceLastWeek
          : averageProfitSinceLastWeek.toFixed(2);
      setAverageProfitSinceLastWeek(averageProfitSinceLastWeek);
    }

    let winRate = simplifyTradingVolume(stats?.winRate || 0);
    winRate = typeof winRate === "string" ? winRate : winRate.toFixed(2);
    setWinRate(winRate);

    let avgTrade = simplifyTradingVolume(stats?.avgTrade || 0);
    avgTrade = typeof avgTrade === "string" ? avgTrade : avgTrade.toFixed(2);
    setAvgTrade(avgTrade);

    let potentialTradingAccount = simplifyTradingVolume(
      stats?.potentialTradingAccount || 0
    );
    potentialTradingAccount =
      typeof potentialTradingAccount === "string"
        ? potentialTradingAccount
        : potentialTradingAccount.toFixed(2);
    setPotentialTradingAccount(potentialTradingAccount);

    if (
      stats?.userGrowthInWeekNumber !== undefined &&
      stats?.userGrowthInWeekNumber !== 0
    ) {
      setUserGrowthInWeekNumber(stats?.userGrowthInWeekNumber);
    }

    let lastTrade = simplifyTradingVolume(stats?.lastTrade || 0);
    lastTrade =
      typeof lastTrade === "string" ? lastTrade : lastTrade.toFixed(2);
    setLastTrade(lastTrade);

    let tradeAmount = simplifyTradingVolume(stats?.tradeAmount || 0);
    tradeAmount =
      typeof tradeAmount === "string" ? tradeAmount : tradeAmount.toFixed(2);
    setTradeAmount(tradeAmount);

    setTotalTrades(parseInt(stats?.totalTrades));

    let PerformanceYTD = simplifyTradingVolume(stats?.PerformanceYTD || 0);
    PerformanceYTD =
      typeof PerformanceYTD === "string"
        ? PerformanceYTD
        : PerformanceYTD.toFixed(2);
    setPerformanceYTD(PerformanceYTD);

    let monthlyGrowth = simplifyTradingVolume(
      stats?.monthlyGrowth?.growth || 0
    );
    monthlyGrowth =
      typeof monthlyGrowth === "string"
        ? monthlyGrowth
        : monthlyGrowth.toFixed(2);
    setMonthlyGrowth(monthlyGrowth);

    if (
      stats?.thisWeekPnlPercentage !== undefined &&
      stats?.thisWeekPnlPercentage !== 0
    ) {
      setThisWeekPnlPercentage(stats?.thisWeekPnlPercentage?.toFixed(2));
    }

    if (
      stats?.growthSinceLastMonth !== undefined &&
      stats?.growthSinceLastMonth !== 0
    ) {
      let growthSinceLastMonth = simplifyTradingVolume(
        stats?.growthSinceLastMonth || 0
      );
      growthSinceLastMonth =
        typeof growthSinceLastMonth === "string"
          ? growthSinceLastMonth
          : growthSinceLastMonth.toFixed(2);
      setGrowthSinceLastMonth(growthSinceLastMonth);
    }

    setAppLinks(user?.memberCommunities[0]?.community?.appLinks);
  }, [stats, user]);

  const getUrl = (url) => {
    if (url.startsWith("http")) {
      return url;
    } else {
      return `https://${url}`;
    }
  };

  return (
    <>
      <div className="mainDashboardStats">
        <div className="row align-items-stretch">
          <div className="col-lg-6 col-12">
            <div className="statsCard firstCard">
              <p className="CP-fs-19 fw-bold title">Your trading account</p>
              <p className="CP-fs-25 fw-bold amount">${yourTradingAccount}</p>
              {balanceSinceLastWeek && (
                <p className="CP-fs-14 fw-medium desc">
                  <span className="CP-fs-14 fw-medium text-green">
                    {balanceSinceLastWeek}%
                  </span>{" "}
                  since last week (${balanceSinceLastWeek})
                </p>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="statsCard black secondCard">
              <div className="row g-0">
                <div className="col-8">
                  <p className="CP-fs-19 fw-bold title">Daily Avg. Profit</p>
                  <p className="CP-fs-25 fw-bold amount">${dailyAvgProfit}</p>
                  {averageProfitSinceLastWeek && (
                    <p
                      className="CP-fs-14 fw-medium desc"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <span className="CP-fs-14 fw-medium text-green">
                        {averageProfitSinceLastWeek}%
                      </span>{" "}
                      since last week
                    </p>
                  )}
                </div>
                <div className="col-4">
                  <div className="text-end">
                    <img src={DailyAvg} alt="DailyAvg" className="DailyAvg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-stretch mb-4 d-lg-none d-md-none">
          <div className="col-12">
            <OpenTrades />
          </div>
        </div>
        <div className="row align-items-stretch">
          <div className="col-lg-6 col-12">
            <div className="row">
              <div className="col-6">
                <div className="statsCardSmall black">
                  <p className="CP-fs-15 fw-bold">Winrate</p>
                  <p className="CP-fs-25 fw-bold">{winRate}%</p>
                </div>
              </div>
              <div className="col-6 ">
                <div className="statsCardSmall black">
                  <p className="CP-fs-15 fw-bold">Avg. trade</p>
                  <p className="CP-fs-25 fw-bold">{avgTrade}%</p>
                </div>
              </div>
            </div>
            <div className="statsCard black tradeHistory SubscriberLeght">
              <p className="CP-fs-19 fw-bold">Trade history</p>
              <p className="CP-fs-14 fw-medium">Get a full overview</p>
              <img
                src={TradeGraph}
                alt="TradeGraph"
                className="tradeGraphImage"
              />
              <Link
                to=""
                className="greenArrowBtn ms-auto"
                onClick={() => {
                  navigate(`/${role}/performance`, {
                    state: {
                      tab: "TradeHistory",
                    },
                  });
                  dispatch(setCurrentTab("TradeHistory"));
                }}
              >
                <img src={moreButton} alt="Arrow" />
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            {role === "member" ? (
              <div className="statsCard black firstCard">
                <p className="CP-fs-19 fw-bold title">
                  Potential trading account next year
                </p>

                <p className="CP-fs-14 fw-medium desc">
                  (If avg. performance stays the same)
                </p>
                <p className="CP-fs-25 fw-bold amount text-green mt-2">
                  ${potentialTradingAccount}
                </p>
              </div>
            ) : (
              <div className="statsCard SubscriberLeght">
                <p className="CP-fs-19 fw-bold">Total subscribers</p>
                <p className="CP-fs-25 fw-bold">{totalSubscribers}</p>
                {userGrowthInWeekNumber && (
                  <p className="CP-fs-14 fw-medium">
                    <span className="CP-fs-14 fw-medium text-green">
                      +{userGrowthInWeekNumber} new subs
                    </span>{" "}
                    since last week
                  </p>
                )}
              </div>
            )}

            <div className="row">
              <div className="col-6">
                <div className="statsCardSmall black">
                  <p className="CP-fs-15 fw-bold">Last trade</p>
                  <p className="CP-fs-25 fw-bold">{lastTrade}%</p>
                </div>
              </div>
              <div className="col-6">
                <div className="statsCardSmall black">
                  {role === "member" ? (
                    <>
                      <p className="CP-fs-15 fw-bold">Total Trade</p>
                      <p className="CP-fs-25 fw-bold">{totalTrades}</p>
                    </>
                  ) : (
                    <>
                      <p className="CP-fs-15 fw-bold">Trading vol. (group)</p>
                      <p className="CP-fs-25 fw-bold">${tradeAmount}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-stretch">
          <div className="col-lg-6 col-12">
            {role === "member" ? (
              <div className="statsCard black secondCard">
                <div className="row">
                  <div className="col-6">
                    <p className="CP-fs-19 fw-bold title">Performance YTD</p>
                    <p className="CP-fs-25 fw-bold amount">{performanceYTD}%</p>
                    {thisWeekPnlPercentage && (
                      <p className="CP-fs-14 fw-medium desc">
                        <span className="CP-fs-14 fw-medium text-green">
                          {thisWeekPnlPercentage}%
                        </span>{" "}
                        since last week
                      </p>
                    )}
                  </div>
                  <div className="col-6">
                    <div className="text-end">
                      <img src={Launch} alt="launch" className="new_launch" />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="statsCard gray fixpaddingGray">
                <div className="row g-0">
                  <div className="col-6">
                    <p className="CP-fs-19 fw-bold">
                      Get your own <br />
                      app from <br />
                      $69/month
                    </p>
                    <Link
                      to=""
                      className="SimpleBlackBtn mt-1"
                      onClick={() => {
                        navigate("/leader/app");
                        dispatch(setCurrentTab("order"));
                      }}
                    >
                      Order Now
                    </Link>
                  </div>
                  <div className="col-6">
                    <div className="text-end position-relative">
                      <img src={mbls} className="mbls" alt="mbls" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="col-lg-6 col-12">
            {role === "member" ? (
              <div className="statsCard black secondCard">
                <div className="row">
                  <div className="col-6">
                    <p className="CP-fs-19 fw-bold title">Monthly Growth</p>
                    <p className="CP-fs-25 fw-bold amount">${monthlyGrowth}</p>
                    {growthSinceLastMonth && (
                      <p className="CP-fs-14 fw-medium desc">
                        <span className="CP-fs-14 fw-medium text-green">
                          {growthSinceLastMonth}%
                        </span>{" "}
                        since last month
                      </p>
                    )}
                  </div>
                  <div className="col-6">
                    <div className="text-end">
                      <img src={Launch} alt="launch" className="new_launch" />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="statsCard black paddingBtmFix">
                <p className="CP-fs-25 fw-bold">Create a promotion</p>
                <p className="CP-fs-14 fw-medium">
                  Great way to grow your business
                </p>
                <div className="d-flex align-items-end justify-content-between">
                  <Link
                    to=""
                    className="greenArrowBtn"
                    onClick={() => {
                      navigate("/leader/performance", {
                        state: {
                          tab: "createPromotion",
                        },
                      });
                      dispatch(setCurrentTab("createPromotion"));
                    }}
                  >
                    <img src={moreButton} alt="Arrow" />
                  </Link>
                  <img src={Discount} className="PercentImg" alt="Discount" />
                </div>
              </div>
            )}
          </div>
        </div>
        {role === "member" ? (
          appLinks && (
            <div className="row">
              <div className="col-12">
                <div className="downloadCard longCard gray">
                  <div className="row align-items-center">
                    <div className="col-lg-7">
                      <p className="CP-fs-20 fw-bold">Download our app now</p>
                      <div className="d-flex align-items-center justify-content-start gap-2 mt-3">
                        {appLinks.ios && (
                          <div className="text-end">
                            <Link
                              to=""
                              className="SimpleBlackBtn"
                              onClick={() =>
                                window.open(getUrl(appLinks.ios), "_blank")
                              }
                            >
                              Apple
                            </Link>
                          </div>
                        )}
                        {appLinks.android && (
                          <div className="text-end">
                            <Link
                              to=""
                              className="SimpleBlackBtn"
                              onClick={() =>
                                window.open(getUrl(appLinks.android), "_blank")
                              }
                            >
                              Android
                            </Link>
                          </div>
                        )}
                        {appLinks.other && (
                          <div className="text-end">
                            <Link
                              to=""
                              className="SimpleBlackBtn"
                              onClick={() =>
                                window.open(getUrl(appLinks.other), "_blank")
                              }
                            >
                              Other
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="text-end">
                        <img src={mbls} alt="mbls" width="150px" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="statsCard longCard">
                <div className="row align-items-center">
                  <div className="col-lg-7">
                    <p className="CP-fs-20 fw-bold">
                      Auto share your trade results
                    </p>
                    <p className="CP-fs-12 fw-medium">
                      Automatically share every trade result to your social
                      channels.
                    </p>
                  </div>
                  <div className="col-lg-2">
                    <div className="d-flex align-items-center justify-content-center gap-1">
                      <div>
                        <Link to="">
                          <img src={X} width="27px" alt="X" />
                        </Link>
                      </div>
                      <div>
                        <Link to="" className="d-block mb-1">
                          <img src={Telegram} width="27px" alt="Telegram" />
                        </Link>
                        <Link to="" className="d-block">
                          <img src={Discord} width="27px" alt="Discord" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="text-end">
                      <Link
                        to=""
                        className="SimpleBlackBtn"
                        onClick={() => {
                          navigate("/leader/performance", {
                            state: { tab: "autoShare" },
                          });
                          dispatch(setCurrentTab("autoShare"));
                        }}
                      >
                        Set it up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardStats2;

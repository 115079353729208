import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import Telegram from "../../../assets/images/telegram.jpg";
import Discord from "../../../assets/images/discord.svg";
import Authorize from "../../../assets/images/authorize.svg";
import X from "../../../assets/images/x.jpg";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../redux/users/usersSlice";
import { setLoading } from "../../../redux/loading/loadingSlice";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import copyIcon from "../../../assets/images/Copy.svg";
import infoIcon from "../../../assets/images/info.svg";
import RenderLinks from "../../../components/links/RenderLinks";

const socials = [
  { value: "Select Social Platform", label: "Select Social Platform" },
  { value: "Telegram", label: "Telegram", icon: Telegram },
  { value: "Discord", label: "Discord", icon: Discord },
  { value: "X", label: "X", icon: X },
];

const Option = (props) => {
  if (props?.children !== "Select Social Platform") {
    return (
      <components.Option {...props} className="country-option">
        <img src={props.data.icon} alt="logo" className="country-logo-big" />
        {props.data.label}
      </components.Option>
    );
  }
};

const messageFormatOptions = [
  {
    value: {
      sendAsText: true,
    },
    label: "Share results as text",
  },
  {
    value: {
      sendAsText: false,
    },
    label: "Share results as image",
  },
];

// Options for the second dropdown
const messageTimeOptions = [
  {
    value: {
      isCloseOrdersOnly: true,
    },
    label: "Close orders only",
  },
  {
    value: {
      isCloseOrdersOnly: false,
    },
    label: "Open orders + Close orders",
  },
];

function AutoShare() {
  const api = useApi();
  const dispatch = useDispatch();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [serverId, setServerId] = useState("");
  const [channelName, setChannelName] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [platformName, setPlatformName] = useState("");
  const [chatId, setChatId] = useState("");
  const user = useSelector((value) => value?.user?.user);
  const [selectedMessageFormat, setSelectedMessageFormat] = useState(); // Initial selection
  const [selectedMessageTime, setSelectedMessageTime] = useState();
  const [messageOptionsChange, setMessageOptionsChange] = useState(false);

  useEffect(() => {
    const twitterAuth = localStorage.getItem("twitter");
    if (twitterAuth) {
      setSelectedPlatform(socials[3]);
      setPlatformName("X");
      localStorage.removeItem("twitter");
    } else {
      setSelectedPlatform(socials[0]);
    }
  }, []);

  const handleTooltipClick = () => {
    setTooltipVisible(!tooltipVisible);
  };

  const handleDiscord = async () => {
    if (!channelName) {
      toastify("Channel name is missing.", "error");
      return;
    }
    if (!serverId) {
      toastify("Server ID is missing.", "error");
      return;
    }

    const requestBody = {
      name: channelName,
      communityId: user?.leaderCommunity?._id,
      serverId,
    };

    dispatch(setLoading(true));

    const response = await api(
      "post",
      "social/createDiscordChannel",
      requestBody
    );
    if (response?.status) {
      toastify(response?.message, "success");
      dispatch(updateUser(response?.data));
      dispatch(setLoading(false));
      setChannelName("");
      setServerId("");
    } else {
      dispatch(setLoading(false));
      toastify(response?.message, "error");
    }
    dispatch(setLoading(false));
  };

  const handleTelegram = async () => {
    dispatch(setLoading(true));
    if (!chatId) {
      toastify("Chat id is missing.", "error");
      dispatch(setLoading(false));
      return;
    }

    const requestBody = {
      chatId,
      communityId: user?.leaderCommunity?._id,
    };

    const response = await api("post", "social/verifyTelegram", requestBody);
    if (response?.status) {
      dispatch(setLoading(false));
      dispatch(updateUser(response?.data));
      toastify(response?.message, "success");
    } else {
      dispatch(setLoading(false));
      toastify(response?.message, "error");
    }
  };

  const twitterOauth = async () => {
    // dispatch(setLoading(true));
    localStorage.setItem("twitter", "true");
    if (user?.twitterCodeVerifier && user?.twitterRefreshToken) {
      toastify("You have already Authorized with Twitter.", "error");
      // dispatch(setLoading(false));
      return;
    }
    const response = await api("get", "social/twitterOAuth");
    if (response?.status) {
      // window.location.assign(response?.data?.url);
      window.open(response?.data?.url, "_blank");
      toastify(response?.message, "success");

      // dispatch(setLoading(false));
    } else {
      // dispatch(setLoading(false));
      toastify(response?.message, "error");
    }
  };

  const handleButtonClick = () => {
    // Replace the URL with your Discord OAuth URL
    const discordOAuthURL =
      "https://discord.com/api/oauth2/authorize?client_id=1275052346581323936&permissions=3089&scope=bot";

    //mine
    // const discordOAuthURL =
    //   "https://discord.com/oauth2/authorize?client_id=1317465797349740546&permissions=268438576&scope=bot";

    // Open the URL in a new tab
    window.open(discordOAuthURL, "_blank");
  };

  const handleCopyyyItBotURL = () => {
    const CopyyyItBotURL = "https://t.me/CopyTrading_Results_bot";

    //mine
    // const CopyyyItBotURL = "https://t.me/CopyItBot";

    window.open(CopyyyItBotURL, "_blank");
  };
  const handleChange = (value) => {
    setSelectedPlatform(value);
    setPlatformName(value?.value);
  };

  useEffect(() => {
    const { sendAsText, isCloseOrdersOnly } = user.messagePermissions;

    const userMessageFormat = messageFormatOptions.find(
      (option) => option.value.sendAsText === sendAsText
    );
    const userMessageTime = messageTimeOptions.find(
      (option) => option.value.isCloseOrdersOnly === isCloseOrdersOnly
    );

    setSelectedMessageFormat(userMessageFormat);
    setSelectedMessageTime(userMessageTime);
  }, [user]);

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {selectedPlatform ? (
        <>
          {selectedPlatform?.icon && (
            <img
              src={selectedPlatform.icon}
              alt="s-logo"
              className="selected-logo"
            />
          )}
          {children}
        </>
      ) : (
        <div className="placeholder">Your New Placeholder Text</div>
      )}
    </components.SingleValue>
  );

  const handleSocialReset = async (platform) => {
    dispatch(setLoading(true));
    const response = await api("post", "social/reset", { platform });

    if (response?.status) {
      toastify(`${platform} disconnected successfully!`, "success");
      dispatch(updateUser(response?.data));
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
      toastify(response?.message);
    }
  };

  const handleMessageFormatChange = (selectedOption) => {
    setMessageOptionsChange(true);
    setSelectedMessageFormat(selectedOption);
  };

  const handleMessageSentTimeChange = (selectedOption) => {
    setMessageOptionsChange(true);
    setSelectedMessageTime(selectedOption);
  };

  const updateMessageOptions = async () => {
    setMessageOptionsChange(false);
    const messagePermissions = {
      sendAsText: selectedMessageFormat?.value?.sendAsText,
      isCloseOrdersOnly: selectedMessageTime?.value?.isCloseOrdersOnly,
    };
    await api("post", "users/update", { messagePermissions });
    fetchUserData();
  };

  const fetchUserData = async () => {
    const myResponse = await api("get", "users/index");
    if (myResponse) {
      dispatch(updateUser(myResponse?.data?.user));
    }
  };

  return (
    <div className="connection autoshareJs p-top mt-sm-30">
      <div className="row">
        <div className="col-lg-12">
          <div className="title">
            <h3>Share your trading results automatically</h3>
            <p>
              Please select how you’d like to share your results and the social
              channel(s) you want to share your results to.
            </p>
            <h2>How would you like to share trading results?</h2>
          </div>

          <div className="sendingPermissionContainer">
            <div className="sendingPermissionselect">
              <div className="select">
                <Select
                  value={selectedMessageFormat}
                  onChange={handleMessageFormatChange}
                  options={messageFormatOptions}
                  placeholder="Select format"
                  isSearchable={false}
                  styles={{
                    control: (base) => ({
                      ...base,
                      width: "220px", // Added width
                      height: "56px", // Added height
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(180deg)"
                        : null,
                      transition: "transform 0.3s ease",
                    }),
                    option: (base, state) => ({
                      ...base,
                      fontSize: "14px",
                      backgroundColor: "white",
                      fontFamily: "Graphik",
                      fontSize: "14px",
                      fontWeight: "bold",
                      lineHeight: "22px",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: "#06152b",
                      cursor: "pointer",
                    }),
                  }}
                />
              </div>
            </div>

            {/* Second Dropdown */}

            <div className="sendingPermissionselect">
              <div className="select">
                <Select
                  value={selectedMessageTime}
                  onChange={handleMessageSentTimeChange}
                  options={messageTimeOptions}
                  placeholder="Select style"
                  isSearchable={false}
                  styles={{
                    control: (base) => ({
                      ...base,
                      width: "220px", // Added width
                      height: "56px", // Added height
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(180deg)"
                        : null,
                      transition: "transform 0.3s ease",
                    }),
                    option: (base, state) => ({
                      ...base,
                      fontSize: "14px",
                      backgroundColor: "white",
                      fontFamily: "Graphik",
                      fontSize: "14px",
                      fontWeight: "bold",
                      lineHeight: "22px",
                      fontStyle: "normal",
                      textAlign: "left",
                      color: "#06152b",
                    }),
                  }}
                />
              </div>
            </div>
          </div>

          <h2>Connect your channels to share results to</h2>
          <div className="mx-5360">
            <div className="select mt-4 mb-4">
              <Select
                value={selectedPlatform}
                options={socials}
                onChange={handleChange}
                isSearchable={false}
                placeholder="Select social channel"
                // menuIsOpen={true}
                styles={{
                  singleValue: (base) => ({
                    ...base,
                    display: "flex",
                    alignItems: "center",
                    zIndex: 999,
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    transform: state.selectProps.menuIsOpen
                      ? "rotate(180deg)"
                      : null,
                    transition: "transform 0.3s ease",
                  }),
                }}
                components={{
                  Option,
                  SingleValue,
                }}
              />
            </div>
            {platformName === "X" && (
              <div className="autoInput">
                {!user?.leaderCommunity?.socials?.twitterCodeVerifier &&
                  !user?.leaderCommunity?.socials?.twitterRefreshToken && (
                    <h3>
                      You need to authorize copyyy.it to post on your Twitter
                      channel.
                    </h3>
                  )}
                {user?.leaderCommunity?.socials?.twitterCodeVerifier &&
                user?.leaderCommunity?.socials?.twitterRefreshToken ? (
                  <div className="d-flex align-items-center justify-content-between mt-4">
                    <div className="authorizeBtn">
                      <button className="authorize-btn text_GM">
                        <img src={Authorize} alt="" /> Authorized
                      </button>
                    </div>
                    <div className="redBtn">
                      <button
                        className="red-btn text_GM"
                        onClick={() => handleSocialReset("X")}
                      >
                        Disconnect
                      </button>
                    </div>
                  </div>
                ) : (
                  <button
                    className="black-btn promoteBtn w-auto"
                    onClick={twitterOauth}
                  >
                    Authorize now
                  </button>
                )}
              </div>
            )}
            {platformName === "Telegram" && (
              <>
                {!user?.leaderCommunity?.socials?.telegramChatId && (
                  <div className="autoInput">
                    <h3>
                      1. Connect with our bot which will guide you to fetch your
                      ‘Chat ID’.
                    </h3>
                    <button
                      className="black-btn promoteBtn w-auto"
                      onClick={handleCopyyyItBotURL}
                    >
                      Open Telegram
                    </button>
                  </div>
                )}
                <div className="autoInput">
                  {!user?.leaderCommunity?.socials?.telegramChatId ? (
                    <>
                      <h3>
                        2. Add the ‘Chat ID’ you copied from Telegram message
                      </h3>
                      <input
                        type="text"
                        placeholder="Enter Chat ID"
                        value={chatId}
                        onChange={(e) => {
                          setChatId(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <h3>3. Telegram ‘Chat ID’</h3>
                      <input
                        type="text"
                        placeholder="Chat ID"
                        value={user?.leaderCommunity?.socials?.telegramChatId}
                        disabled
                      />
                    </>
                  )}
                  {chatId &&
                    !user?.leaderCommunity?.socials?.telegramChatId && (
                      <div className="mt-4">
                        <button
                          className="cyan-btn text_GM"
                          onClick={handleTelegram}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  {user?.leaderCommunity?.socials?.telegramChatId && (
                    <div className="d-flex align-items-center justify-content-between mt-4">
                      <div className="authorizeBtn">
                        <button className="authorize-btn text_GM">
                          <img src={Authorize} alt="" /> Now sharing
                        </button>
                      </div>
                      <div className="redBtn">
                        <button
                          className="red-btn text_GM"
                          onClick={() => handleSocialReset("Telegram")}
                        >
                          Disconnect
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {platformName === "Discord" &&
              !user?.leaderCommunity?.socials?.discordChannelId && (
                <>
                  <div className="autoInput">
                    {/* <h3>
                                                1. Create a channel on your
                                                discord server to share results
                                                to and then add that name here.
                                            </h3> */}
                    {/* <input
                                                type="text"
                                                placeholder="Enter channel name"
                                                value={channelName}
                                                onChange={(e) => {
                                                    setChannelName(
                                                        e.target.value
                                                    );
                                                }}
                                            /> */}
                  </div>
                  <div className="autoInput">
                    <h3>1. Connect our bot to your discord server</h3>
                    <button
                      className="black-btn promoteBtn w-auto"
                      onClick={handleButtonClick}
                    >
                      Open Discord
                    </button>
                  </div>
                  <div className="autoInput">
                    <h3>
                      2. Once connected please add the server ID and channel
                      name here{" "}
                      <img
                        src={infoIcon}
                        alt="Info Icon"
                        className="info-icon mb-1"
                        onClick={() => handleTooltipClick()}
                        title="Turn on ‘developer mode’ in advanced setting, right click your server icon and click 'Copy Server ID'"
                      />{" "}
                    </h3>
                    {tooltipVisible && (
                      <p className="mb-2">
                        {" "}
                        Turn on ‘developer mode’ in advanced setting, right
                        click your server icon and click 'Copy Server ID'
                      </p>
                    )}
                    <input
                      type="text"
                      className="mb-4"
                      placeholder="Enter server ID"
                      value={serverId}
                      onChange={(e) => {
                        setServerId(e.target.value);
                      }}
                    />
                    <input
                      type="text"
                      placeholder="Enter channel name"
                      value={channelName}
                      onChange={(e) => {
                        setChannelName(e.target.value);
                      }}
                    />
                    {serverId && channelName && (
                      <div className="mt-4">
                        <button
                          className="cyan-btn text_GM"
                          onClick={handleDiscord}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}

            {platformName === "Discord" &&
              user?.leaderCommunity?.socials?.discordChannelId && (
                <>
                  <div className="autoInput">
                    <h3>Channel Name</h3>
                    <div className="position-relative d-flex align-items-center justify-content-end">
                      <input
                        type="text"
                        placeholder="Enter channel name"
                        value={
                          user?.leaderCommunity?.socials?.discordChannelName
                        }
                        disabled
                      />
                      <span className="position-absolute">
                        <img
                          className="cursor-pointer"
                          onClick={() => {
                            navigator.clipboard
                              .writeText(
                                user?.leaderCommunity?.socials
                                  ?.discordChannelName
                              )
                              .then(() => {
                                toastify(
                                  "Discord Channel Name copied to clipboard",
                                  "success"
                                );
                              })
                              .catch((error) => {
                                console.error("Clipboard write error:", error);
                                toastify(
                                  "Failed to Discord Channel Name to clipboard.",
                                  "error"
                                );
                              });
                          }}
                          src={copyIcon}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="autoInput">
                    <h3>Channel URL</h3>
                    <div className="position-relative d-flex align-items-center justify-content-end">
                      <input
                        type="text"
                        placeholder="Enter channel name"
                        value={
                          user?.leaderCommunity?.socials?.discordChannelUrl
                        }
                        disabled
                      />
                      <span className="position-absolute">
                        <img
                          className="cursor-pointer"
                          onClick={() => {
                            navigator.clipboard
                              .writeText(
                                user?.leaderCommunity?.socials
                                  ?.discordChannelUrl
                              )
                              .then(() => {
                                toastify(
                                  "Discord Channel URL copied to clipboard",
                                  "success"
                                );
                              })
                              .catch((error) => {
                                console.error("Clipboard write error:", error);
                                toastify(
                                  "Failed to Discord Channel URL to clipboard.",
                                  "error"
                                );
                              });
                          }}
                          src={copyIcon}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="autoInput mb-0">
                    <h3>Channel ID</h3>
                    <div className="position-relative d-flex align-items-center justify-content-end">
                      <input
                        type="text"
                        placeholder="Enter channel name"
                        value={user?.leaderCommunity?.socials?.discordChannelId}
                        disabled
                      />
                      <span className="position-absolute">
                        <img
                          className="cursor-pointer"
                          onClick={() => {
                            navigator.clipboard
                              .writeText(
                                user?.leaderCommunity?.socials?.discordChannelId
                              )
                              .then(() => {
                                toastify(
                                  "Discord Channel ID copied to clipboard",
                                  "success"
                                );
                              })
                              .catch((error) => {
                                console.error("Clipboard write error:", error);
                                toastify(
                                  "Failed to copy Discord Channel ID to clipboard.",
                                  "error"
                                );
                              });
                          }}
                          src={copyIcon}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="redBtn mt-4 mb-4">
                    <button
                      className="red-btn text_GM"
                      onClick={() => handleSocialReset("Discord")}
                    >
                      Disconnect
                    </button>
                  </div>
                </>
              )}

            {messageOptionsChange && (
              <div className="mt-4">
                <button
                  className="cyan-btn text_GM"
                  onClick={updateMessageOptions}
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <RenderLinks page="performanceShare" />
    </div>
  );
}

export default AutoShare;

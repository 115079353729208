import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { setLoading } from "../../../redux/loading/loadingSlice";
import { toastify } from "../../../helpers/toast/toastify";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function Payment({ community }) {
  const [clientSecret, setClientSecret] = useState("");
  const dispatch = useDispatch();
  const api = useApi();

  useEffect(() => {
    const createCheckout = async () => {
      dispatch(setLoading(true));
      try {
        const response = await api("post", "users/checkoutSession", {
          communityId: community?.communityId,
          subscription: community?.subscription,
          plan: community?.plan,
        });

        if (response?.status) {
          setClientSecret(response?.data?.clientSecret);
          dispatch(setLoading(false));
        } else {
          toastify(response?.message);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
      }
    };

    const executeSequentially = async () => {
      await createCheckout();
    };

    executeSequentially();
  }, []);

  return (
    <div id="checkout" style={{ width: "600px", margin: "0 auto" }}>
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
}

export default Payment;

import React, { useState, useEffect } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useSelector } from "react-redux";
import { toastify } from "../../../helpers/toast/toastify";
import { missingPopup } from "../../../helpers/dataHelper/missingData";
import useApi from "../../../helpers/apiHelper/requestHelper";
import RenderLinks from "../../../components/links/RenderLinks";
import Multiselect from "multiselect-react-dropdown";
import Cross from "../../../assets/images/cross.svg";

function Message({ user, currentTab, setUser }) {
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("name");
  const [data, setData] = useState({ message: "", subject: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const ref = useDetectClickOutside({
    onTriggered: () => {
      setShow(false);
      setSearchTerm("");
    },
  });
  const api = useApi();
  const [users, setUsers] = useState([]);

  const [selected, setSelected] = useState([]);
  const [selectedTier, setSelectedTier] = useState();

  const participants = useSelector(
    (state) => state?.user?.user?.leaderCommunity?.participants
  );
  const leaderCommunity = useSelector(
    (state) => state?.user?.user?.leaderCommunity
  );

  useEffect(() => {
    if (user) {
      setSelected([user]);
    }
    if (currentTab == "membersOverviews") {
      setSelected([]);
    }
  }, [user, currentTab]);

  useEffect(() => {
    if (participants) {
      // Map participants to get the user objects
      const userObjects = participants?.map((e) => e?.user);

      // Filter the user objects based on the search term
      const filteredUsers = userObjects?.filter((user) =>
        user?.email?.toLowerCase()?.includes(searchTerm.toLowerCase())
      );

      setUsers(filteredUsers);
    }
  }, [searchTerm]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && searchTerm) {
      const firstUser = users.find((user) => user.email.includes(searchTerm));
      if (firstUser) {
        handleUserSelect(firstUser);
        setShow(!show); // Close the dropdown
        setSearchTerm("");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [searchTerm, users]);

  const handleUserSelect = (user) => {
    const isSelected = selected.some((s) => s._id === user._id);

    if (isSelected) {
      setSelected((prevSelected) =>
        prevSelected.filter((s) => s._id !== user._id)
      );
    } else {
      setSelected((prevSelected) => [...prevSelected, user]);
    }

    setShow(false); // Close the dropdown
    setSearchTerm("");
  };

  const sendMessage = async () => {
    if (selected?.length === 0) toastify("Users are missing.");
    else if (!data?.subject) missingPopup("Subject");
    else if (!data?.message) missingPopup("Message");
    else {
      const response = await api("post", "users/sendMessage", {
        ...data,
        users: selected,
      });

      if (response?.status) {
        toastify("Emails sent successfully.", "success");
        setData({ message: "", subject: "" });
        setSelected([]);
      } else {
        toastify(response?.message);
      }
    }
  };

  useEffect(() => {
    if (participants) {
      setUsers(participants?.map((e) => e.user));
    }
  }, [participants]);

  const selectAll = () => {
    if (users.length > 0) {
      setSelected(users);
      setActiveTab("");
    }
    setActiveTab("");
  };

  const removeEmail = (emailToRemove) => {
    setSelected(selected.filter((item) => item.email !== emailToRemove));
  };

  return (
    <React.Fragment>
      <div className="memberMessage">
        <div className="connection">
          <div className="mx-554">
            <h2>Message Member</h2>
            <div className="custom-dropdown d-flex" ref={ref}>
              <button
                className="messageMemberInput"
                onClick={() => setShow(!show)}
              >
                {selected?.length > 0 ? (
                  selected?.map((item) => (
                    <div className="d-flex justify-content-between align-items-center">
                      <div key={item.email} className="text_GM color-black">
                        {item.email}
                      </div>
                      <img
                        src={Cross}
                        onClick={() => removeEmail(item.email)}
                        alt="Cross"
                        className="select-user-cross-icon"
                      />
                    </div>
                  ))
                ) : (
                  <div>Member(s) to message</div>
                )}
              </button>
              {show && (
                <div className="dropmenu">
                  <div className="membersSearch">
                    <div className="tabs">
                      <button
                        className={`tab ${
                          activeTab === "name" ? "active" : ""
                        }`}
                        onClick={() => setActiveTab("name")}
                      >
                        Email
                      </button>
                      {/* <button
                                                className={`tab ${
                                                    activeTab === "tier"
                                                        ? "active"
                                                        : ""
                                                }`}
                                                onClick={() =>
                                                    setActiveTab("tier")
                                                }
                                            >
                                                Tier
                                            </button> */}
                      <button
                        className={`tab ${activeTab === "all" ? "active" : ""}`}
                        onClick={() => {
                          setActiveTab("all");
                          setSearchTerm("");
                        }}
                      >
                        All
                      </button>
                    </div>
                    {activeTab === "name" && (
                      <div className="tab-contents">
                        <input
                          type="text"
                          placeholder="Search email"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />

                        {searchTerm && (
                          <div className="mt-2">
                            {users?.map((user) => (
                              <p
                                key={user._id}
                                className={`mt-2 fs15 cursor-pointer ${
                                  selected.some((s) => s._id === user._id)
                                    ? "selected"
                                    : ""
                                }`}
                                onClick={() => handleUserSelect(user)}
                              >
                                {user?.email}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                    {activeTab === "tier" && (
                      <div className="tab-contents">
                        {leaderCommunity?.subscription?.map((s) => (
                          <p
                            className={`cursor-pointer pb-3 ${
                              selectedTier === s?._id && "bold"
                            }`}
                            onClick={() => {
                              if (selectedTier === s?._id) {
                                setSelectedTier();
                              } else {
                                setSelectedTier(s?._id);
                              }
                            }}
                          >
                            Tier: ${s?.monthlyfee} / ${s?.yearlyfee}
                          </p>
                        ))}
                        {selectedTier && (
                          <div className="mt-2">
                            {users
                              ?.filter(
                                (e) =>
                                  e?.memberCommunities[0]?.subscription ===
                                    selectedTier && !selected?.includes(e)
                              )
                              ?.map((user) => (
                                <p
                                  key={user._id}
                                  className={`mt-2 fs15 cursor-pointer ${
                                    selected.some((s) => s._id === user._id)
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => handleUserSelect(user)}
                                >
                                  {user?.email}
                                </p>
                              ))}
                          </div>
                        )}
                      </div>
                    )}
                    {activeTab === "all" && (
                      <div>
                        <button
                          onClick={selectAll}
                          // disabled={users.length === 0}
                          className={`black-btn promoteBtn text_GM ${
                            users.length === 0 ? "cursorNotAllowed" : ""
                          }`}
                        >
                          Select All
                        </button>
                        {/* <Multiselect
                                                    className="form-control multiselect"
                                                    options={
                                                        selected.length ===
                                                            users?.length
                                                            ? users
                                                            : users.concat({
                                                                email: "Select All",
                                                            })
                                                    }
                                                    selectedValues={selected}
                                                    onSelect={(e) => {
                                                        let found = e.find(
                                                            (f) =>
                                                                f.email ===
                                                                "Select All"
                                                        );
                                                        if (found) {
                                                            setSelected(users);
                                                            return;
                                                        }
                                                        setSelected(e);
                                                    }}
                                                    onRemove={(e) =>
                                                        setSelected(e)
                                                    }
                                                    displayValue="email"
                                                /> */}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="messageInput">
              <div className="form-floating">
                <input
                  id="subject"
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                  value={data?.subject}
                  onChange={(e) =>
                    setData({
                      ...data,
                      subject: e.target.value,
                    })
                  }
                  maxLength={100}
                />
                <label for="subject">Subject</label>
              </div>
            </div>
            <div className="messageInput">
              <textarea
                placeholder="Type your message here"
                rows="6"
                value={data?.message}
                onChange={(e) =>
                  setData({
                    ...data,
                    message: e.target.value,
                  })
                }
              />
            </div>
            <div
              className="d-flex justify-content-end pb-sm-50px"
              style={{ marginTop: "44px" }}
            >
              <button
                className="black-btn promoteBtn text_GM"
                onClick={sendMessage}
              >
                Send
              </button>
            </div>
          </div>
          <RenderLinks page="membersMessage" />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Message;

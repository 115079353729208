import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import Discount from "../../../assets/images/4.gif";
import Circle from "../../../assets/images/MoreButton.svg";
import Dollar from "../../../assets/images/3.gif";
import Users from "../../../assets/images/5.gif";
import RenderLinks from "../../../components/links/RenderLinks";
import { setLoading } from "../../../redux/loading/loadingSlice";
import { updateUser } from "../../../redux/users/usersSlice";
import { toastify } from "../../../helpers/toast/toastify";
import { saveAs } from "file-saver";
import { setCurrentTab } from "../../../redux/links/linksSlice";
import { simplifyTradingVolume } from "../../../helpers/helper";

export default function Business({ setTab }) {
  const [stats, setStats] = useState({});
  const api = useApi();
  const dispatch = useDispatch();
  const user = useSelector((value) => value?.user?.user);
  const community = useSelector((state) => state?.user?.user?.leaderCommunity);

  const getUserStats = async () => {
    const response = await api("get", "stats/index");
    setStats(response?.data);
  };

  useEffect(() => {
    getUserStats();
  }, []);

  const formatValueWithPlus = (value) => {
    if (value === null) {
      return "0";
    }

    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      return "0";
    }

    return numericValue >= 0 ? `${numericValue}` : numericValue;
  };

  const downloadCsv = async () => {
    const response = await api("post", "users/getPayoutHistory");
    if (response?.success) {
      const base64Data = response.base64File;
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `Payouts_${new Date().getFullYear()}.xlsx`);
    } else {
      toastify(response?.message);
    }
  };

  const handleCheckbox = async () => {
    dispatch(setLoading(true));
    const response = await api("post", "community/update", {
      id: user?.leaderCommunity?._id,
      receiveEmailPayouts: !user?.leaderCommunity?.receiveEmailPayouts,
    });
    if (response?.status) {
      dispatch(updateUser(response?.data));
      setTimeout(() => {
        dispatch(setLoading(false));
        toastify("Email preference set successfully!", "success");
      }, 2000);
    } else {
      toastify(response?.message);
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      <div className="stats_cards">
        <div className="statusTrading">
          <p>
            Status:{" "}
            {user?.binanceCredentials?.isConnected ||
            user?.bybitCredentials?.isConnected
              ? "Trading"
              : "Not Trading"}
          </p>
          <p>
            {user?.binanceCredentials?.isConnected &&
            user?.bybitCredentials?.isConnected
              ? "Connected to: Binance and ByBit"
              : user?.bybitCredentials?.isConnected
              ? "Connected to: ByBit"
              : user?.binanceCredentials?.isConnected
              ? "Connected to: Binance"
              : "Not Connected"}
          </p>
        </div>
        <div className="card_flex card_flex_mbl">
          <div className="white_card">
            <div className="h-100 d-flex flex-column justify-content-center">
              <p className="text-truncate color-black  bold fs19">
                Total subscribers
              </p>
              <h4 className="text-truncate color-black bold fs25">
                {user?.leaderCommunity?.participants?.length || 0}
              </h4>
              {stats?.subSinceLastWeek == undefined ? (
                ""
              ) : stats?.subSinceLastWeek != 0 ? (
                <p className="text-truncate color-black">
                  <span className="plus cyan-color fs14">
                    + {stats?.subSinceLastWeek || 0} new subs
                  </span>{" "}
                  since last week
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="black_card">
            <div className="d-flex h-100 justify-content-between align-items-center">
              <div className="mbl-100">
                <p className="text-truncate  bold fs19">Total monthly income</p>
                <h4 className="text-truncate bold fs25">
                  {"$"}
                  {simplifyTradingVolume(stats?.monthlyIncome || 0)}
                </h4>
                {stats?.incomeSinceLastWeek == undefined ? (
                  ""
                ) : stats?.incomeSinceLastWeek != 0 ? (
                  <p className="text-truncate">
                    <span className="plus cyan-color fs14">
                      ${stats?.incomeSinceLastWeek || 0}
                    </span>{" "}
                    since last week
                  </p>
                ) : (
                  ""
                )}
              </div>
              <img
                className="w-60 dollar"
                src={Dollar}
                width="91px"
                alt="Dollar"
              />
            </div>
          </div>
        </div>
        <div className="card_flex card_flex_mbl">
          <div className="mbl-100">
            <div className="card_flex flex-no">
              <div className="smallBlack_card">
                <p className="text-truncate  bold fs15">Trading vol. (group)</p>
                <h4 className="text-truncate bold fs25">
                  $
                  {(() => {
                    const simplifiedValue = simplifyTradingVolume(
                      stats?.tradeAmount || 0
                    );
                    return typeof simplifiedValue === "string"
                      ? simplifiedValue
                      : simplifiedValue.toFixed(2);
                  })()}
                </h4>
              </div>
              <div className="smallBlack_card">
                <p className="text-truncate  bold fs15">Avg. membership</p>
                <h4 className="text-truncate bold fs25">
                  {stats?.membersMonthAvg || 0} months
                </h4>
              </div>
            </div>
            <div className="black_card position-relative">
              <p className=" fs19 community_heading lh-27">
                Create a promotion
              </p>
              <p>Great way to grow your business</p>
              <div className="mobile-arrow-btn-fix d-flex justify-content-between align-items-end">
                <div
                  className="arrowBtn"
                  onClick={() => dispatch(setCurrentTab("createPromotion"))}
                >
                  <img src={Circle} alt="circle" />
                </div>
                <img
                  className="mt-0 w-60"
                  style={{ marginBottom: "-8px" }}
                  width="76px"
                  src={Discount}
                  alt="Discount"
                />
              </div>
            </div>
          </div>
          <div className="mbl-100">
            <div className="black_card">
              <div className="h-100 d-flex flex-column justify-content-between flex-wrap">
                <div className="h-100 d-flex justify-content-between align-items-center">
                  <div className="mbl-100">
                    <p className="community_heading text-truncate  bold fs19">
                      Monthly growth
                    </p>
                    <h4 className="text-truncate bold fs25 py-1">
                      {(stats?.usersGrowthInMonth || 0).toFixed(2)}%
                    </h4>
                    {stats?.weeklyUsersGrowth == undefined ? (
                      ""
                    ) : stats?.weeklyUsersGrowth != 0 ? (
                      <p className="text-truncate">
                        <span className="plus cyan-color fs14">
                          {stats?.weeklyUsersGrowth || 0}
                          {"%"}
                        </span>{" "}
                        since last week
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <img className="w-60" src={Users} width="82px" alt="users" />
                </div>
              </div>
            </div>
            <div className="card_flex flex-no">
              <div className="smallBlack_card">
                <p className="text-truncate  bold fs15">Income YTD</p>
                <h4 className="text-truncate bold fs25">
                  {"$"}
                  {simplifyTradingVolume(stats?.totalIncome || 0)}
                </h4>
              </div>
              <div className="smallBlack_card green-background">
                <p className="text-truncate  bold fs15">Total trades</p>
                <h4 className="text-truncate bold fs25">
                  {parseInt(stats?.totalTrades || 0)}
                </h4>
              </div>
            </div>
          </div>
        </div>
        {user?.role === "leader" && (
          <div className="col-12 align-items-end w-692 mx-auto business_community mb-5">
            <div className="link_content">
              <p className="community_heading fs20">Your payout history</p>
              <p className="mb-1 fs12">Download your monthly payouts TYD</p>
              <button className="black-btn text_GM" onClick={downloadCsv}>
                Download Now
              </button>
            </div>
            <div className="d-flex align-items-center">
              <p className="fs12">Email me the monthly the payout receipts</p>
              <div className="customCheck">
                <input
                  type="checkbox"
                  id="myCheckboxs"
                  onChange={handleCheckbox}
                  checked={user?.leaderCommunity?.receiveEmailPayouts}
                />
                <label
                  htmlFor="myCheckboxs"
                  className="custom-checkbox"
                ></label>
              </div>
            </div>
          </div>
        )}
        <RenderLinks page="performanceBusiness" />
      </div>
    </>
  );
}

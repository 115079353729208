import React, { useState, useEffect } from "react";
import Copy from "../../../assets/images/Copy.svg";
import Delete from "../../../assets/images/Delete.svg";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { setLoading } from "../../../redux/loading/loadingSlice";
import RenderLinks from "../../../components/links/RenderLinks";

function CreatePromotion() {
  const [discounts, setDiscounts] = useState([]);
  const [code, setCode] = useState("");
  const [percentage, setPercentage] = useState("");
  const user = useSelector((state) => state.user.user);
  const api = useApi();
  const dispatch = useDispatch();

  const community = user?.leaderCommunity;

  const getDiscounts = async () => {
    const response = await api("get", "discount/index");
    if (response?.status) {
      setDiscounts(response?.data);
    } else {
      toastify(response?.message);
    }
  };

  useEffect(() => {
    getDiscounts();
  }, []);

  const handlePercentageChange = (e) => {
    const value = e.target.value;
    if (value === "" || (Number(value) <= 100 && Number(value) >= 0)) {
      setPercentage(value);
    }
  };

  const handleCreateDiscount = async () => {
    if (!code?.trim() && !percentage?.trim()) {
      toastify("Please fill the required fields", "error");
      dispatch(setLoading(false));
      return;
    } else if (!percentage?.trim()) {
      toastify("Please input discount in percentages", "error");
      return;
    } else if (percentage === "0") {
      toastify("Discount cannot be 0.");
      return;
    } else if (code?.length < 6) {
      toastify("Promo code should be (6-8 characters)");
      return;
    }
    dispatch(setLoading(true));

    // Validate that both code and percentage are provided before making the API call

    try {
      const response = await api("post", "discount/create", {
        code,
        percentage,
        community,
      });

      if (response?.status) {
        toastify("Discount code created successfully", "success");
        setDiscounts(response?.data);
        setCode("");
        setPercentage("");
      } else {
        toastify(response?.message, "error");
      }
    } catch (error) {
      console.error("Error creating discount:", error);
      toastify("An error occurred while creating the discount", "error");
    }

    dispatch(setLoading(false));
  };

  const handleDeleteDiscount = async (id) => {
    dispatch(setLoading(true));
    const response = await api("post", "discount/deleteDiscount", {
      id,
    });
    if (response?.status) {
      toastify(response?.message, "success");
      getDiscounts();
    } else {
      toastify(response?.message);
    }

    dispatch(setLoading(false));
  };

  return (
    <React.Fragment>
      <div className="connection autoshareJs p-top mx-691 mt-sm-30">
        <div className="title">
          <h3>Create your discount codes</h3>
          <p>
            You can create as many as you want. Each code will apply to the
            first month of a membership for a new member when signing up.
          </p>
        </div>
        <div className="discount">
          <div className="discountInputs">
            <div className=" pb-4 messageInputs">
              <div className="customInput">
                <div className="form-floating">
                  <input
                    id="discount"
                    // ref={nameRef}
                    type="text"
                    className="form-control fs15"
                    placeholder="Discount (%)"
                    value={percentage}
                    onChange={handlePercentageChange}
                    maxLength={
                      percentage && parseFloat(percentage) < 10 ? 4 : 5
                    }
                  />
                  <label for="discount">Discount (%)</label>
                </div>
              </div>
            </div>

            <div className=" pb-4 messageInputs">
              <div className="customInput">
                <div className="form-floating">
                  <input
                    id="promotion"
                    type="text"
                    className="form-control fs15"
                    placeholder="Promotion code (6-8 characters)"
                    value={code}
                    onChange={(e) => {
                      const upperCaseValue = e.target.value.toUpperCase();
                      if (
                        /^[A-Z0-9]*$/.test(upperCaseValue) ||
                        upperCaseValue === ""
                      ) {
                        setCode(upperCaseValue);
                      }
                    }}
                    maxLength={8}
                    minLength={6}
                  />

                  <label for="discount">Promotion code (6-8 characters)</label>
                </div>
              </div>
            </div>
          </div>

          <div className="discountBtn discountInputs">
            <button
              className="black-btn text_GM"
              onClick={handleCreateDiscount}
            >
              Create
            </button>
          </div>
        </div>
        {discounts?.length > 0 && (
          <div className="discount mx-407">
            <div className="title">
              <p>Discounts</p>
            </div>
            {discounts?.map((d) => (
              <div className="card">
                <div className="block">
                  <h3>{d?.percentage}%</h3>
                </div>
                <div className="block">
                  <h3>{d?.code}</h3>
                  <div className="d-flex align-items-center justify-content-center">
                    <img
                      src={Copy}
                      alt="copy"
                      onClick={() => {
                        navigator.clipboard.writeText(`${d?.code}`);
                        toastify(
                          "Discount code copied to clipboard!",
                          "success"
                        );
                      }}
                    />
                    <img
                      src={Delete}
                      alt="Delete"
                      onClick={() => handleDeleteDiscount(d?._id)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <RenderLinks page="performancePromotion" />
      </div>
    </React.Fragment>
  );
}

export default CreatePromotion;

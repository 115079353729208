import React, { useCallback, useEffect, useState } from "react";
// import { api } from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import { updateUser } from "../../../redux/users/usersSlice";
import Modal from "react-bootstrap/Modal";
import { FloatingLabel, Form, Button } from "react-bootstrap";
import { setLoading } from "../../../redux/loading/loadingSlice";
import anglesDown from "../../../assets/images/angles-down.svg";
import closeIcon from "../../../assets/images/close_danger.svg";
import stripeIcon from "../../../assets/images/stripe.png";
import paymentIcons from "../../../assets/images/payment-icons.png";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../../helpers/apiHelper/requestHelper";

export default function MemberSubscriptions() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  return (
    <Elements stripe={stripePromise}>
      <Child />
    </Elements>
  );
}

const Child = () => {
  const api = useApi();
  const [allCommunities, setAllCommunities] = useState([]);
  const [selectedCommunity, setSelectedCommunity] = useState();
  const [complete, setComplete] = useState(false);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tab, setTab] = useState(1);
  const [discount, setDiscount] = useState("");
  const [search, setSearch] = useState("");
  const user = useSelector((state) => state.user.user);
  const isLoading = useSelector((state) => state?.loader?.isLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const stripe = useStripe();
  const elements = useElements();

  const todayDate = moment().format("DD-MM-YYYY");

  const memberCommunity = user?.memberCommunities[0]?.community?.name;

  let CardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        "::placeholder": {
          color: "#aab7c4",
        },
        // backgroundColor: "pink",
        padding: 100,
        margin: 100,
        borderWidth: "1px",
        border: "2px solid red",
      },
      complete: { color: "green" },
      empty: { color: "red" },
      invalid: {
        color: "red",
      },
    },
  };

  useEffect(() => {
    populatePackages();
  }, [user?.memberCommunities]);

  const populatePackages = async () => {
    const response = await api("get", "community/index");

    if (response?.status) {
      setAllCommunities(response?.data?.communities);
    }
  };

  const createSubscription = async () => {
    try {
      dispatch(setLoading(true));

      const response = await api("post", "users/handlePayments", {
        communityId: id,
      });
      if (response.status) {
        dispatch(updateUser(response?.data?.user));
        dispatch(setLoading(false));
        toastify("You have been subscribed successfully.", "success");
      } else {
        toastify(response?.message);
      }
    } catch (err) {
      console.log(err);
      // toastify("Payment Unsuccessful");
    }
  };

  useEffect(() => {
    if (id) {
      createSubscription();
    } else {
      populatePackages();
    }
    dispatch(setLoading(false));
  }, []);

  const handleCheckout = async (com) => {
    try {
      if (com?.community?._id) {
        dispatch(setLoading(true));
        const response = await api("post", "users/checkoutSession", {
          communityId: com?.community?._id,
          // discount,
        });
        if (response.status) {
          // dispatch(updateUser(response?.data?.user));
          dispatch(setLoading(false));
          toastify(response.message, "success");
          if (response?.data?.url) {
            window.location.assign(response?.data?.url);
          } else if (
            response?.data?.user?.onboarding?.subscribed &&
            response?.data?.user?.firstVisit
          ) {
            navigate("/member/dashboard");
          }
        } else {
          toastify(response.message);
          dispatch(setLoading(false));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const yesAction = async () => {
    dispatch(setLoading(true));
    setShowModal(false);
    const response = await api("post", "users/unsubscribe", {
      sessionId: selectedCommunity?.sessionId,
      communityId: selectedCommunity.community._id,
      userId: user._id,
    });

    if (response?.status) {
      toastify("You have been unsubscribed successfully!", "success");
      dispatch(updateUser(response?.data?.user));
    } else {
      toastify(response?.message);
    }

    dispatch(setLoading(false));
  };

  const noAction = () => {
    setShowModal(false);
  };

  return (
    <div className="row justify-content-center h-100 p-0">
      {showModal && (
        <ConfirmationModal
          yesAction={yesAction}
          noAction={noAction}
          text="Are you sure you want to cancel the subscription?"
        />
      )}
      {/* {show && (
                <Modal
                    centered
                    animation
                    show={show}
                    onHide={() => setShow(false)}
                    style={{
                        zIndex: isLoading ? 1 : 1050,
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Subscription</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="payment-sub-header px-3">
                            Feel secure to enter the payment credentials below.
                            We don't save any payment credentials on our server.
                        </div>
                        <div
                            className="my-4 p-4 bg-grey"
                        >
                            <CardElement
                                options={CardElementOptions}
                                onChange={(e) => {
                                    setComplete(e.complete);
                                }}
                            />
                        </div>
                        <div>
                            <Form.Group
                                className="mb-3 d-flex align-items-end justify-content-between"
                                controlId="exampleForm.ControlInput1"
                            >
                                <div className="d-flex flex-column">
                                    <Form.Label>Discount Code</Form.Label>
                                    <Form.Control
                                        className="discount-field"
                                        placeholder="Enter discount code"
                                        value={discount}
                                        maxLength={8}
                                        onChange={(e) => {
                                            if (
                                                /^[a-zA-Z0-9]+$/.test(
                                                    e.target.value
                                                ) ||
                                                e.target.value === ""
                                            ) {
                                                setDiscount(e.target.value);
                                            }
                                        }}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <img
                            src={stripeIcon}
                            className="me-auto"
                            style={{ width: 100 }}
                        />
                        <Button
                            variant="success"
                            className="px-4"
                            // onClick={createSubscription}
                            disabled={!complete}
                        >
                            Pay Now
                        </Button>
                    </Modal.Footer>
                </Modal>
            )} */}
      {/* {showOnboarding && (
                <OnboardingModal
                    noAction={() => {
                        setShowOnboarding(false);
                    }}
                    step="Step 1/2"
                    heading="Subscribe"
                    description="First you need to subscribe."
                />
            )} */}
      <div className="col-lg-10 m-subscription d-flex flex-column">
        {user?.firstVisit && (
          <h4 className="community_name mb-4">{memberCommunity}</h4>
        )}
        <div className="page_title d-sm-flex align-items-sm-center justify-content-sm-between">
          <h4 className="fs25 text_GB d-block text-left">Subscription Plan</h4>
          {/* <ul className="tabs_list fs25 pt-sm-0 pt-4 d-flex align-items-center">
                        <li
                            className="cursor-pointer"
                            aria-expanded={tab === 1}
                            onClick={() => setTab(1)}
                        >
                            My Subs
                        </li>
                        <li
                            className="cursor-pointer"
                            aria-expanded={tab === 2}
                            onClick={() => setTab(2)}
                        >
                            Add Subs
                        </li>
                    </ul> */}
        </div>

        <div className="h-100 d-flex flex-column align-items-center">
          <div className="page_form d-grid gap-3 mt-sm-3 pt-sm-3 pb-sm-5">
            {user?.memberCommunities?.map((com, i) => {
              const originalPrice = parseFloat(
                com?.community?.subscription?.price
              );
              const newPrice = originalPrice + 10;
              return (
                <div
                  key={i}
                  className={`text-box-wrapper connect_box text-white radius16 px-4 py-3 min_h72 d-flex flex-column justify-content-center ${
                    com?.subscribed ? "active-sub" : ""
                  }`}
                >
                  <div className="d-flex align-items-center">
                    <div className="text-box">
                      <h4 className="fs18 mb-2 mt-3">
                        {com?.community?.subscription?.name?.toUpperCase()}
                      </h4>

                      <h5 className="fs18">
                        {com?.subscribed ? (
                          <span className="font-bold">
                            <span
                              className={`subscription-price ${
                                parseFloat(com?.paidAmount) <
                                parseFloat(newPrice)
                                  ? "strike-through"
                                  : ""
                              }`}
                            >
                              ${newPrice}
                            </span>
                            {parseFloat(com?.paidAmount) <
                              parseFloat(newPrice) && (
                              <span className="fs40 text-white">
                                ${com?.paidAmount}
                              </span>
                            )}
                          </span>
                        ) : (
                          <span className="font-bold text-white">
                            ${newPrice}
                          </span>
                        )}
                        / MONTH
                      </h5>

                      {/* <p className="mt-3">
                                                Min.{" "}
                                                {
                                                    com?.community?.subscription
                                                        ?.numberOfTrades
                                                }{" "}
                                                trades
                                                {" " +
                                                    com?.community?.subscription
                                                        ?.frequency}
                                                .
                                            </p> */}
                      <p>
                        {com?.community?.subscription?.portfolioPercentage}% ROI
                        / MONTH
                      </p>
                      <p>{com?.community?.subscription?.pair?.join(", ")}</p>
                    </div>
                    <div className="m-auto center mt-5 mb-3 common_style">
                      <div className="mb-2">
                        {new Date(com?.subscriptionExpiry) >=
                          new Date(todayDate) && !com?.subscribed ? (
                          <p className="fs16 bold">
                            Subscription is Active till:{" "}
                            {moment(com?.subscriptionExpiry).format(
                              "DD-MM-YYYY"
                            )}
                          </p>
                        ) : null}
                      </div>
                      <button
                        onClick={() => {
                          setSelectedCommunity(com);
                          if (com?.subscribed) {
                            setShowModal(true);
                          } else {
                            handleCheckout(com);
                          }
                        }}
                        className="btn btn-success radius h_60 w_260 fs20 px-5"
                        disabled={
                          new Date(com?.subscriptionExpiry) >=
                            new Date(todayDate) && !com?.subscribed
                        }
                      >
                        {com?.subscribed ? "Unsubscribe" : "Subscribe"}
                      </button>
                    </div>

                    {/*<a*/}
                    {/*    className="m-auto text_dark75"*/}
                    {/*>*/}
                    {/*    <i className="bi bi-arrow-right-short fs40 border-start border-white text-white"></i>*/}
                    {/*</a>*/}
                  </div>
                </div>
              );
            })}
            {user?.memberCommunities?.length > 0 ? (
              <div className="mt-3 safe-checkout">
                <div className="d-flex align-items-center text-start">
                  <small>
                    Guaranteed{" "}
                    <strong
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      safe &amp; secure
                    </strong>{" "}
                    checkout
                  </small>
                  <img
                    src={stripeIcon}
                    className="ms-2"
                    // style={{ width: "7rem" }}
                  />
                </div>
                <div className="line my-4"></div>
                <div className="payment-img d-flex align-items-center justify-content-center mb-5">
                  <img
                    src={paymentIcons}
                    className="ms-2"
                    style={{
                      minWidth: "100%",
                      width: "100%",
                    }}
                  />
                </div>
                <div className="text-muted">
                  You can cancel your subscription at any time. If you cancel
                  your subscription then there’s no refund for the remaining
                  period and no new trades are executed after your cancellation
                  but ongoing trades continue as nothing ever happened. You can
                  find more information in our{" "}
                  <a
                    className="text_muted4 text_GB"
                    onClick={() =>
                      window.open("https://www.copyyy.it/terms-and-conditions")
                    }
                  >
                    T&C’s here
                  </a>
                  .
                </div>
              </div>
            ) : (
              <div className="mt-3 no-subs">
                <div>You are not subscribed to any subscription yet.</div>
                {/* <button
                                        className="btn btn-success radius min_h57 fs20 px-5"
                                        onClick={() => setTab(2)}
                                    >
                                        Browse Here
                                    </button> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
